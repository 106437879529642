import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';

// SimpleBar
import SimpleBar from "simplebar-react";

import { searchEverything } from '../../slices/utilities/thunk';

const searchLinks = {
    customer: 'customers/{customer_id}',
    contact: 'customers/{customer_id}#4',
    site: 'customers/{customer_id}#2',
    inventory: 'customers/{customer_id}#8',
    service: 'customers/{customer_id}#3',
};

const SearchOption = () => {
    const dispatch = useDispatch();
    const [value, setValue] = useState("");
    const [results, setResults] = useState([]);
    const [dropdownWidth, setDropdownWidth] = useState('100%');

    // Handle search input changes
    const onChangeData = async (value) => {
        setValue(value);
        if (value.trim()) {
            dispatch(searchEverything({ query: value, page: 1, limit: 5 }))
                .then((response) => {
                    setResults(response.payload.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setResults([]);
        }
    };

    // Handle input and dropdown visibility
    useEffect(() => {
        const searchInput = document.getElementById("search-options");
        const dropdown = document.getElementById("search-dropdown");
        const searchOptions = document.getElementById("search-close-options");

        // Adjust dropdown width dynamically based on input field's width
        const updateDropdownWidth = () => {
            const inputWidth = searchInput.getBoundingClientRect().width;
            setDropdownWidth(`${inputWidth}px`);
        };

        searchInput.addEventListener("focus", function () {
            if (searchInput.value.length > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            }
        });

        searchInput.addEventListener("keyup", function () {
            if (searchInput.value.length > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            } else {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        searchOptions.addEventListener("click", function () {
            searchInput.value = "";
            dropdown.classList.remove("show");
            searchOptions.classList.add("d-none");
            setResults([]); // Clear search results
        });

        document.body.addEventListener("click", function (e) {
            if (e.target.getAttribute('id') !== "search-options") {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        // Update the dropdown width on load and resize
        updateDropdownWidth();
        window.addEventListener("resize", updateDropdownWidth);

        // Cleanup event listeners
        return () => {
            window.removeEventListener("resize", updateDropdownWidth);
        };
    }, []);

    // Function to generate dynamic search links based on result type and id
    const generateSearchLink = (result) => {
        const { type, customer_id } = result;
        const linkTemplate = searchLinks[type];
        if (type === 'inventory' && !customer_id) {
            return 'inventory-items-list';
        } else {
            return linkTemplate ? linkTemplate.replace('{customer_id}', customer_id) : '#';
        }
    };

    return (
        <React.Fragment>
            <form className="app-search d-none d-md-block" style={{width:'100%'}}>
                <div className="position-relative">
                    <Input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        id="search-options"
                        value={value}
                        onChange={e => onChangeData(e.target.value)}
                    />
                    <span className="mdi mdi-magnify search-widget-icon"></span>
                    <span
                        className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                        id="search-close-options"
                    ></span>
                </div>
                <div
                    className="dropdown-menu dropdown-menu-lg"
                    id="search-dropdown"
                    style={{ width: dropdownWidth }}
                >
                    <SimpleBar style={{ height: "320px" }}>
                        <div className="dropdown-header">
                            <h6 className="text-overflow text-muted mb-0 text-uppercase">Search Results</h6>
                        </div>

                        {results && results.length > 0 ? (
                            results.map((result, index) => (
                                <Link
                                    to={`/${generateSearchLink(result)}`}
                                    className="dropdown-item notify-item d-flex align-items-center"
                                    key={index}
                                >
                                    <span>{result.name}</span>
                                    <span className="badge bg-primary rounded-pill ms-2">{'type: ' + result.type}</span>
                                    <span className="badge bg-primary rounded-pill ms-2">{'customer id: ' + result.customer_id}</span>
                                </Link>
                            ))
                        ) : (
                            <div className="dropdown-item bg-transparent text-wrap">
                                <span>No results found</span>
                            </div>
                        )}
                    </SimpleBar>

                    <div className="text-center pt-3 pb-1">
                        <Link to={`/global-search?query=${value}`} className="btn btn-primary btn-sm">
                            View All Results <i className="ri-arrow-right-line ms-1"></i>
                        </Link>
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
};

export default SearchOption;
