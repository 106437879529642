import { createSlice } from "@reduxjs/toolkit";
import {
  searchDatabase,
  getDatabaseStats,
  getUsage,
  findCustomerByImsi,
  findServiceByImsi,
  listImsiByCustomerId,
  removeActionPlan,
  topupDongle,
} from './thunk';

export const initialState = {
  databaseSearchResults: [],
  databaseStats: {},
  error: {},
  usage: {},
  usageError: {},
  imsiCustomer: {},
  imsiCustomerError: {},
  imsiService: {},
  imsiListError: {},
  imsiList: [],
  imsiListError: {}
};

const OamSlice = createSlice({
  name: 'OamSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(searchDatabase.fulfilled, (state, { payload }) => {
      state.databaseSearchResults = payload;
    });
    builder.addCase(searchDatabase.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getDatabaseStats.fulfilled, (state, { payload }) => {
      state.databaseStats = payload;
    });
    builder.addCase(getDatabaseStats.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder
      .addCase(getUsage.fulfilled, (state, { payload }) => {
        state.usage = payload;
      })
      .addCase(getUsage.rejected, (state, { payload }) => {
        state.usageError = payload;
      });
    builder
      .addCase(findCustomerByImsi.fulfilled, (state, { payload }) => {
        state.imsiCustomer = payload;
      })
      .addCase(findCustomerByImsi.rejected, (state, { payload }) => {
        state.imsiCustomerError = payload;
      });
    builder
      .addCase(findServiceByImsi.fulfilled, (state, { payload }) => {
        state.imsiService = payload;
      })
      .addCase(findServiceByImsi.rejected, (state, { payload }) => {
        state.imsiServiceError = payload;
      });
    builder
      .addCase(listImsiByCustomerId.fulfilled, (state, { payload }) => {
        state.imsiList = payload;
      })
      .addCase(listImsiByCustomerId.rejected, (state, { payload }) => {
        state.imsiListError = payload;
      });

    builder
      .addCase(removeActionPlan.fulfilled, (state, { payload }) => {
        state.actionPlanRemoved = payload;
      })
      .addCase(removeActionPlan.rejected, (state, { payload }) => {
        state.actionPlanRemovedError = payload;
      });

    builder
      .addCase(topupDongle.fulfilled, (state, { payload }) => {
        state.dongleTopup = payload;
      })
      .addCase(topupDongle.rejected, (state, { payload }) => {
        state.dongleTopupError = payload;
      });
  }
});

export default OamSlice.reducer;