// /src/components/ServiceList.jsx
import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import TableCommon from "../../../../Components/Common/TableCommon";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { random, set } from "lodash";
import PlanList from "../../../Product/PlanList";
import ServiceView from "../../../Services/components/ServiceView";
import Tags from "../../../../Components/Common/Tags";
import { SERVICE_STATUS_THEME_COLORS } from "../../../../Components/constants/crm";
import Addons from "../../../Product/Addons";
import ServiceUsage from "../../../Services/components/ServiceUsage";
import { useDispatch } from "react-redux";
import { getServicesByCustomerId as onGetServicesByCustomerId, deleteService as onDeleteService } from "../../../../slices/thunks";
import Loader from "../../../../Components/Common/Loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ServiceList = ({ customerId, customerName = null, type=null }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [serviceList, setServiceList] = useState([]);
  const [formattedServiceList, setFormattedServiceList] = useState([]);
  const [selectedService, setSelectedService] = useState({});
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 10,
    totalRecords: 0,
    totalPages: 0,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "service_id", direction: "desc" });
  const sortableColumns = ['service_id', 'service_name', 'service_start', 'service_end', 'autorenew', 'service_type', 'retail_cost', 'wholesale_cost'];
  const [filters, setFilters] = useState({ service_status: ['Active'] });

  const [modals, setModals] = useState({
    addServiceModal: false,
    editServiceModal: false,
    addonModal: false,
    deleteModal: false,
    deleteModalMulti: false,
    usageModal: false,
  });

  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);

  const dispatch = useDispatch();

  const sortOptions = [
    { value: 'desc', label: 'Newest' },
    { value: 'asc', label: 'Oldest' },
  ];

  const filterOptions = {
    service_type: {
      options: [
        { value: 'mobile', label: 'Mobile' },
        { value: 'iptv', label: 'IPTV' },
        { value: 'internet', label: 'Internet' },
        { value: 'voip', label: 'VoIP' },
      ],
    },
  };

  const toggleModal = (modalName) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: !prevModals[modalName],
    }));
  };

  const handleServiceEdit = (service) => {
    setSelectedService(service);
    toggleModal('editServiceModal');
  };

  const handleServiceEditRow = (serviceId) => {
    setSelectedService(serviceList.find(service => service.service_id === serviceId));
    toggleModal('editServiceModal');
  };

  const handleAddonClick = (service) => {
    setSelectedService(service);
    toggleModal('addonModal');
    fetchServices();
  };

  const handleUsageClick = (service) => {
    setSelectedService(service);
    toggleModal('usageModal');
  };

  const fetchServices = useCallback((currentPage = pagination.currentPage, itemsPerPage = pagination.itemsPerPage, key = sortConfig.key, direction = sortConfig.direction, search = searchTerm, filtersData = filters) => {
    const sortKeyMapping = {
      service_start: 'service_active_date',
      service_end: 'service_deactivate_date',
      autorenew: 'auto_renew',
    };
    const sortKey = sortKeyMapping[key] || key;

    dispatch(onGetServicesByCustomerId({ customerId, search, page: currentPage, perPage: itemsPerPage, sort: sortKey, sortOrder: direction, filters: filtersData }))
      .then((response) => {
        setSortConfig({ key: sortKey, direction });
        setSearchTerm(search);
        setServices(response.payload.data);
        if (response.payload.pagination) {
          setPagination({
            currentPage: response.payload.pagination.page,
            itemsPerPage: response.payload.pagination.per_page,
            totalRecords: response.payload.pagination.total_records,
            totalPages: response.payload.pagination.total_pages,
          });
        }
        setIsLoading(false);
      });
  }, [dispatch, customerId, pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm, filters]);

  useEffect(() => {
      setServiceList(services);
  }, [services]);

  useEffect(() => {
    fetchServices();
  }, [customerId, fetchServices]);

  useEffect(() => {
    if (serviceList) {
      setFormattedServiceList(serviceList.map((service) => {
        let formattedService = {
          id: service.service_id,
          service_id: service.service_id,
          service_name: service.service_name,
          service_start: service.service_active_date,
          service_end: service.service_deactivate_date,
          autorenew: service.auto_renew ? 'On' : 'Off',
          status: (
            <Tags tags={[{ value: service.service_status, color: SERVICE_STATUS_THEME_COLORS[service.service_status] ?? 'info' }]} />
          ),
          service_type: service.service_type,
          wholesale_cost: null,
          retail_cost: service.retail_cost,
          price: service.retail_cost,
          actions: (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddonClick(service);
                  }}
                  className="text-white d-inline-block btn btn-success btn-sm"
                >
                  <i className="ri-add-line">Add On</i>
                </Link>
              </li>
              {service.customer_id && (
                <li className="list-inline-item">
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUsageClick(service);
                    }}
                    className="text-white d-inline-block btn btn-success btn-sm"
                  >
                    <i className="ri-donut-chart-fill">Usage</i>
                  </Link>
                </li>
              )}
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleServiceEdit(service);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              {/* { type !== 'self-care' && (
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="text-danger d-inline-block remove-item-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedService(service);
                      toggleModal('deleteModal');
                    }}
                  >
                    <i className="ri-delete-bin-5-fill fs-16"></i>
                  </Link>
                </li>
              )} */}
            </ul>
          ),
        };

        if (type !== 'self-care') {
          formattedService['wholesale_cost'] = service.wholesale_cost;
          delete formattedService['price'];
        } else {
          delete formattedService['retail_cost'];
          delete formattedService['wholesale_cost'];
        }

        return formattedService;

      }));
    }
  }, [serviceList]);

  const handleDeleteServiceConfirm = () => {
    dispatch(onDeleteService(selectedService.service_id))
      .then(() => {
        toast.success("Service deleted successfully");
        fetchServices();
        setSelectedService(null);
        toggleModal('deleteModal');
      })
      .catch((error) => {
        toast.error('Failed to delete Service');
        console.error(error);
      });
  };

  const handleDeleteMultiple = () => {
    Promise.all(
      selectedCheckBoxDelete.map((element) => {
        return new Promise((resolve) => {
          dispatch(onDeleteService(element));
          setTimeout(() => {
            toast.clearWaitingQueue();
            resolve();
          }, 3000);
        });
      })
    )
      .then(() => {
        toast.success("Services deleted successfully");
        fetchServices();
        setSelectedCheckBoxDelete([]);
        toggleModal('deleteModalMulti');
      })
      .catch((error) => {
        toast.error("Failed to delete services");
        console.error(error);
      });
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: [value],
    }));
  };

  const applyFilters = () => {
    fetchServices();
  };

  const resetFilters = () => {
    setFilters({ service_status: filters.service_status });
    fetchServices(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm, { service_status: filters.service_status });
  };

  const refresh = () => {
    toggleModal('addServiceModal');
    fetchServices();
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filtersData = null;
      if (['Active', 'Inactive', 'Suspended'].includes(type)) {
        filtersData = { service_status: [type] };
      }
      setFilters(filtersData);
      fetchServices(1, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm, filtersData);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  document.title = "Services | Omnitouch CRM";
  return (
    <div>
      <DeleteModal
        show={modals.deleteModal}
        onDeleteClick={handleDeleteServiceConfirm}
        onCloseClick={() => toggleModal('deleteModal')}
      />
      <DeleteModal
        show={modals.deleteModalMulti}
        onDeleteClick={handleDeleteMultiple}
        onCloseClick={() => toggleModal('deleteModalMulti')}
      />
      <Modal id="addonModal" isOpen={modals.addonModal} toggle={() => toggleModal('addonModal')} size={'xl'} centered>
        <ModalHeader toggle={() => toggleModal('addonModal')}>
          Addons
        </ModalHeader>
        <ModalBody>
          <Addons customerId={selectedService?.customer_id} serviceId={selectedService?.service_id} service={selectedService} simple={type === 'self-care'} />
        </ModalBody>
      </Modal>
      <Modal id="usageModal" isOpen={modals.usageModal} scrollable={true} toggle={() => toggleModal('usageModal')} size={'xl'} centered>
        <ModalHeader toggle={() => toggleModal('usageModal')}>
          Balance
        </ModalHeader>
        <ModalBody>
          <ServiceUsage service={selectedService} />
        </ModalBody>
      </Modal>

      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Services</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <button className="btn btn-success" onClick={() => toggleModal('addServiceModal')}>
                        <i className="ri-add-line me-1 align-bottom"></i> Add Service
                      </button>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success border-bottom border-grey" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1", 'text-success': activeTab === "1", 'text-dark': activeTab !== "1" }, "fw-semibold")}
                        onClick={() => toggleTab("1", "Active")}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i> Active Services
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2", 'text-success': activeTab === "2", 'text-dark': activeTab !== "2" }, "fw-semibold")}
                        onClick={() => toggleTab("2", "Inactive")}
                        href="#"
                      >
                        <i className="ri-stop-circle-line me-1 align-bottom"></i> Inactive Services
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3", 'text-success': activeTab === "3", 'text-dark': activeTab !== "3" }, "fw-semibold")}
                        onClick={() => toggleTab("3", "Suspended")}
                        href="#"
                      >
                        <i className="ri-pause-circle-line me-1 align-bottom"></i> Suspended Services
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4", 'text-success': activeTab === "4", 'text-dark': activeTab !== "4" }, "fw-semibold")}
                        onClick={() => toggleTab("4", "All")}
                        href="#"
                      >
                        <i className="ri-service-fill me-1 align-bottom"></i> All Services
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TableCommon
                    data={formattedServiceList}
                    title="Services"
                    noHeader={true}
                    searchPlaceholder="Search for Services, Service ID, Date etc."
                    sortConfig={sortConfig}
                    sortableColumns={sortableColumns}
                    pagination={pagination}
                    fetchRecords={fetchServices}
                    multiDelete={ type !== 'self-care'  ? (ids) => { setSelectedCheckBoxDelete(ids); toggleModal('deleteModalMulti'); } : null }
                    onRowClick={handleServiceEditRow}
                    sortOptions={sortOptions}
                    noCard={true}
                    filters={{
                      filterOptions: filterOptions,
                      handleFilterChange: handleFilterChange,
                      applyFilters: applyFilters,
                      resetFilters: resetFilters,
                    }}
                  />
                </div>
                <Modal id="showModal" isOpen={modals.addServiceModal} toggle={() => toggleModal('addServiceModal')} size={'xl'} centered>
                  <ModalHeader className="bg-light p-3" toggle={() => toggleModal('addServiceModal')}>
                    Add Service to Customer: {customerName ?? customerId}
                  </ModalHeader>
                  <ModalBody>
                    <PlanList customerId={customerId} refresh={refresh} simple={type === 'self-care'}/>
                  </ModalBody>
                </Modal>
                <Modal id="editModal" isOpen={modals.editServiceModal} toggle={() => toggleModal('editServiceModal')} size={'xl'} centered>
                  <ModalHeader toggle={() => toggleModal('editServiceModal')}>
                    Edit Service
                  </ModalHeader>
                  <ModalBody>
                    <ServiceView customerId={customerId} service={selectedService} onClose={fetchServices} type={type} />
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServiceList;
