import { createSlice } from "@reduxjs/toolkit";
import {
  searchEverything,
} from './thunk';

export const initialState = {
  searchResults: {},
  searchError: null,
};

const UtilitiesSlice = createSlice({
  name: 'UtilitiesSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchEverything.fulfilled, (state, action) => {
        state.searchResults = action.payload;
      })
      .addCase(searchEverything.rejected, (state, action) => {
        state.searchError = action.payload;
      });
  }
});

export default UtilitiesSlice.reducer;