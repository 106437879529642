import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import TableCommon from '../../Components/Common/TableCommon';
import DeleteModal from '../../Components/Common/DeleteModal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CustomerForm from './components/CustomerForm';

import {
    deleteCustomer as onDeleteCustomer,
    getCustomers as onGetCustomers,
    resetCustomer as onResetCustomer
} from "../../slices/thunks";
import { Container } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';

const CustomerList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        customers
    } = useSelector(state => state.Customer);

    const [customerList, setCustomerList] = useState([]);
    const [currentCustomer, setCurrentCustomer] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        itemsPerPage: 10,
        totalRecords: 0,
        totalPages: 0,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'customer_id', direction: 'desc' });
    const [addModal, setAddModal] = useState(false);
    const [editCustomer, setEditCustomer] = useState(null);
    const [isInitialCustomersFetched, setIsInitialCustomersFetched] = useState(false);

    const [filterOptions, setFilterOptions] = useState({
        customer_account_type: {
            options: [
                { value: 'Individual', label: 'Individual' },
                { value: 'Business', label: 'Business' }
            ]
        },
        customer_payment_type: {
            options: [
                { value: 'prepaid', label: 'Prepaid' },
                { value: 'postpaid', label: 'Postpaid' }
            ]
        },
        customer_status: {
            options: [
                { value: 'Open', label: 'Open' },
                { value: 'Closed', label: 'Closed' },
                { value: 'Suspended', label: 'Suspended' },
                { value: 'Archived', label: 'Archived' }
            ]
        },
    });

    const [filters, setFilters] = useState({});

    const handleFilterChange = (field, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [field]: [value],
        }));
    };

    const applyFilters = () => {
        fetchCustomers();
    };

    const resetFilters = () => {
        setFilters({});
    };

    // for use in reset filters to refetch inventory
    useEffect(() => {
        if (Object.keys(filters).length === 0 && isInitialCustomersFetched) {
            fetchCustomers();
        }
    }, [filters]);

    const handleCloseAddModal = () => {
        setAddModal(false);
        setEditCustomer(null);
    };

    const toggleAddModal = () => setAddModal(!addModal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const handleCustomerView = (customerId) => {
        navigate(`/customers/${customerId}`);
    };

    useEffect(() => {
        if (customers.data && Array.isArray(customers.data)) {
            setCustomerList(customers.data.map(customer => {
                let matchedContact = null;
    
                if (searchTerm) {
                    matchedContact = customer.contacts?.find(contact =>
                        (contact.contact_firstname + ' ' + contact.contact_lastname).toLowerCase().includes(searchTerm.toLowerCase())
                    );
                }
    
                let customersData = {
                    matched_contact: matchedContact ? `${matchedContact.contact_firstname} ${matchedContact.contact_lastname}` : null,
                    id: customer.customer_id,
                    name: customer.customer_name ?? 'N/A',
                    payment_type: customer.customer_payment_type,
                    customer_status: customer.customer_status,
                    lead_source: customer.lead_source,
                    actions: (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <li className="list-inline-item">
                                <Link
                                    className="text-primary d-inline-block"
                                    title='View Customer'
                                    to={`/customers/${customer.customer_id}`}
                                    onClick={e => e.stopPropagation()}
                                >
                                <i className="ri-eye-fill fs-16"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link
                                    title={'Delete Customer'}
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleCustomerDelete(customer.customer_id);
                                    }}
                                >
                                <i className="ri-delete-bin-5-fill fs-16"></i>
                                </Link>
                            </li>
                        </ul>
                    )
                }

                // remove matched contact if search term is empty
                if (!searchTerm) {
                    delete customersData.matched_contact;
                }

                return customersData;
            }));
        }
    
        if (customers.pagination) {
            setPagination({
                currentPage: customers.pagination.current_page,
                itemsPerPage: customers.pagination.per_page,
                totalRecords: customers.pagination.total_items,
                totalPages: customers.pagination.total_pages,
            });
        }
    }, [customers, searchTerm]);
    

    const handleCustomerDelete = (id) => {
        setCurrentCustomer(customerList.find(customer => customer.id === id));
        toggleDeleteModal();
    };

    const handleCustomerConfirmDelete = () => {
        dispatch(onDeleteCustomer(currentCustomer.id))
            .then(() => {
                toast.success('Customer deleted successfully', { autoClose: 3000 });
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to delete customer', { autoClose: 3000 });
            })
            .finally(() => fetchCustomers(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm));

        setCurrentCustomer(null);
        setDeleteModal(false);
    };

    const deleteMultipleCustomers = (ids) => {
        setSelectedIds(ids);
        setDeleteModalMulti(true);
    };

    const deleteMultipleCustomersComfirm = () => {
        if (selectedIds.length === 0) {
            return;
        }
        Promise.all(selectedIds.map(id => dispatch(onDeleteCustomer(id))))
            .then(() => {
                toast.success('Customers deleted successfully', { autoClose: 3000 });
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to delete customers', { autoClose: 3000 });
            })
            .finally(() => fetchCustomers(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm));
    };

    const refetchCustomers = (page = pagination.currentPage, perPage = pagination.itemsPerPage, sort = sortConfig.key, sortOrder = sortConfig.direction, search = searchTerm) => {
        if (isInitialCustomersFetched) {
            fetchCustomers(page, perPage, sort, sortOrder, search);
        }
    };

    const fetchCustomers = (page = pagination.currentPage, perPage = pagination.itemsPerPage, sort = sortConfig.key, sortOrder = sortConfig.direction, search = searchTerm) => {
        // map sort to correct field name
        if (sort === 'name') {
            sort = 'customer_name';
        } else if (sort === 'payment_type') {
            sort = 'customer_payment_type';
        }

        dispatch(onGetCustomers({ searchTerm: search, page, perPage, sort, sortOrder, filters}))
            .then(() => {
                setIsInitialCustomersFetched(true);
                setSearchTerm(search);
                setSortConfig({ key: sort, direction: sortOrder });
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to fetch customers', { autoClose: 3000 });
            });
    }

    useEffect(() => {
        // Initial fetch
        fetchCustomers();

        return () => {
            dispatch(onResetCustomer());
        };
    }, []);

    return (
        <React.Fragment>
            <div className='page-content'>
                <Container fluid>
                    <BreadCrumb title="Customers" />
                    <TableCommon
                        title="Customers"
                        data={customerList}
                        pagination={pagination}
                        sortableColumns={['name', 'payment_type', 'customer_status', 'lead_source']}
                        sortConfig={sortConfig}
                        fetchRecords={refetchCustomers}
                        add={toggleAddModal}
                        multiDelete={deleteMultipleCustomers}
                        onRowClick={handleCustomerView}
                        filters={{
                            filterOptions: filterOptions,
                            handleFilterChange: handleFilterChange,
                            applyFilters: applyFilters,
                            resetFilters: resetFilters
                        }}
                    />
                    {/* Add Modal */}
                    <Modal isOpen={addModal} toggle={() => { handleCloseAddModal(); }} centered size='xl'>
                        <ModalHeader className="bg-light p-3" toggle={() => { toggleAddModal(); }}> {editCustomer ? 'Edit' : 'Add'} Customer </ModalHeader>
                        <form className="tablelist-form">
                            <ModalBody>
                                <CustomerForm closeModal={handleCloseAddModal}/>
                            </ModalBody>
                            <ModalFooter>
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={() => { setEditCustomer(null); handleCloseAddModal(); }}>Close</button>
                                </div>
                            </ModalFooter>
                        </form>
                    </Modal>
                    <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleCustomerConfirmDelete}
                        onCloseClick={() => setDeleteModal(false)}
                    />
                    <DeleteModal
                        show={deleteModalMulti}
                        onDeleteClick={() => {
                            deleteMultipleCustomersComfirm();
                            setDeleteModalMulti(false);
                        }}
                        onCloseClick={() => setDeleteModalMulti(false)}
                    />
                </Container>
            </div>
            

        </React.Fragment>
    )
}

export default CustomerList;