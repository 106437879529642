import { createSlice } from "@reduxjs/toolkit";
import {
    getStripeObjects,
    addNewStripeObject,
    deleteStripeObject,
    getStripeObjectsByCustomerId,
    addStripePaymentForAnInvoice,
    chargeStripePayment,
    createPaymentIntent,
    resetStripeStates,
    refundStripePayment,
} from './thunk';

export const initialState = {
    cards: [],
    card: {},
    isCardCreated: false,
    error: null,
    paymentIntent: {},
    paymentIntentError: null
};

const StripeSlice = createSlice({
    name: 'StripeSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getStripeObjects.fulfilled, (state, action) => {
            state.cardsAll = action.payload;
        });
        builder.addCase(getStripeObjects.rejected, (state, action) => {
            state.errorAll = action.payload?.error || null;
        });
        builder.addCase(addNewStripeObject.fulfilled, (state, action) => {
            state.card = action.payload;
            state.isCardCreated = true;
        });
        builder.addCase(addNewStripeObject.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(deleteStripeObject.fulfilled, (state, action) => {
            state.cards = state.cards.filter((card) => card.id !== action.payload);
        });
        builder.addCase(deleteStripeObject.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(getStripeObjectsByCustomerId.fulfilled, (state, action) => {
            state.cards = action.payload;
        });
        builder.addCase(getStripeObjectsByCustomerId.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(addStripePaymentForAnInvoice.fulfilled, (state, action) => {
            state.card = action.payload;
        });
        builder.addCase(addStripePaymentForAnInvoice.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(chargeStripePayment.fulfilled, (state, action) => {
            state.card = action.payload;
        });
        builder.addCase(chargeStripePayment.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(resetStripeStates.fulfilled, (state, action) => {
            state.cards = [];
            state.card = {};
            state.isCardCreated = false;
            state.error = null;
        });
        builder
            .addCase(createPaymentIntent.fulfilled, (state, action) => {
                state.paymentIntent = action.payload;
            })
            .addCase(createPaymentIntent.rejected, (state, action) => {
                state.paymentIntentError = action.payload;
            });
        builder.addCase(resetStripeStates.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(refundStripePayment.fulfilled, (state, action) => {
            state.refund = action.payload;
        });
        builder.addCase(refundStripePayment.rejected, (state, action) => {
            state.error = action.payload || null;
        });
    }
});

export default StripeSlice.reducer;