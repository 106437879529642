import React, { useState, useEffect} from 'react'
import { Col, Container, Modal, Row, ModalBody, ModalFooter, ModalHeader, Button, Input} from 'reactstrap';
import TableCommon from '../../Components/Common/TableCommon';

//Import actions
import {
    deleteStripeObject as onDeleteStripeObject,
    addNewStripeObject as onAddNewStripeObject,
    getStripeObjectsByCustomerId as onGetStripeObjectsByCustomerId,
    resetStripeStates as onResetStripeStates,
    getStripeObjects as onGetStripeObjects,
} from "../../slices/thunks";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import AddPaymentMethodModal from './components/AddPaymentMethodModal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Link } from 'react-router-dom';
import DeleteModal from '../../Components/Common/DeleteModal';
import ErrorModal from '../../Components/Common/ErrorModal';

const PaymentMethods = ({ customer = null }) => {
    const dispatch = useDispatch();

    // get stripe key from env
    const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? 'pk_test_51NYLueJyScbvk8qvDtdK50Lj4Lpju0gfCS0zzSSXSaaTRgISO8AT4b9VuZPmhvtorper4N1dvPUgsIqHlJyzznos009ugmBuTI';

    const stripePromise = loadStripe(stripeKey);

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [hasFetchedPaymentMethods, setHasFetchedPaymentMethods] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [pagination, setPagination] = useState({
        currentPage: 1,
        itemsPerPage: 10,
        totalRecords: 0,
        totalPages: 0,
    });
    const [searchTerm, setSearchTerm] = useState('');

    const toggleAddModal = () => setAddModal(!addModal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const fetchPaymentMethods = () => {
        if (customer && customer?.customer_id) {
            dispatch(onGetStripeObjectsByCustomerId({customerId: customer?.customer_id}))
                .then(response => {
                    setPaymentMethods(response.payload.data);
                    setPagination({
                        ...pagination,
                        currentPage: response.payload.pagination.page,
                        totalRecords: response.payload.pagination.total_records,
                        totalPages: Math.ceil(response.payload.pagination.total_pages / pagination.itemsPerPage),
                    });
                });
            setHasFetchedPaymentMethods(true);
        } else {
            dispatch(onGetStripeObjects({ page: pagination.currentPage, perPage: pagination.itemsPerPage, searchTerm }))
                .then(response => {
                    setPaymentMethods(response.payload.data);
                    setPagination({
                        ...pagination,
                        currentPage: response.payload.pagination.page,
                        totalRecords: response.payload.pagination.total_records,
                        totalPages: Math.ceil(response.payload.pagination.total_pages / pagination.itemsPerPage),
                    });
                }
            );
            setHasFetchedPaymentMethods(true);
        }
    };

    useEffect(() => {
        fetchPaymentMethods();
    }, [dispatch]);

    const formattedPaymentMethods = paymentMethods && paymentMethods.map((method) => {
        let formattedPaymentMethod = {
            id: method.customer_stripe_id,
            Nickname: method.card_nickname,
            customer_id: !customer && method.customer_id,
            Issuer: method.cc_type,
            Expiry: `${method.exp_month}/${method.exp_year}`,
            Added: new Date(method.created).toLocaleDateString(),
            Default: method.default_payment_method ? 'Yes' : 'No',
            actions: (
                <ul className="list-inline hstack gap-2 mb-0">
                    <li className="list-inline-item">
                        <Link
                        title={'Delete'}
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={(e) => {
                            e.stopPropagation();
                            handlePaymentMethodDelete(method.customer_stripe_id);
                        }}
                        >
                        <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Link>
                    </li>
                </ul>
            ),
        };

        if (customer) {
            delete formattedPaymentMethod.customer_id;
        }

        return formattedPaymentMethod;
    }
    );

    const handlePaymentMethodDelete = (id) => {
        // check if there are other payment methods and that the payment method for deletion is not the default
        const paymentMethodToDelete = paymentMethods?.find(paymentMethod => paymentMethod.customer_stripe_id === id);
        if (paymentMethods.length > 1 && !paymentMethodToDelete.default_payment_method) {
            setCurrentPaymentMethod(paymentMethodToDelete);
            toggleDeleteModal();
        } else {
            setErrorModalMessage('Cannot delete the default payment method or when there are no other payment methods');
            setErrorModal(true);
            setCurrentPaymentMethod(null);
        }
    };

    const handlePaymentMethodConfirmDelete = () => {
        dispatch(onDeleteStripeObject(currentPaymentMethod.customer_stripe_id)).then(() => {
            dispatch(onGetStripeObjectsByCustomerId(customer?.customer_id))
                .then(() => {
                    toast.success('Payment method deleted successfully',
                        {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                });
        });

        // Reset the current payment method
        setCurrentPaymentMethod(null);

        // Close the delete modal
        toggleDeleteModal();
    };

    const onToggleAddModal = () => {
        toggleAddModal();
        dispatch(onGetStripeObjectsByCustomerId(customer?.customer_id));
    }

    const handleOnAddPaymentMethodClose = () => {
        toggleAddModal();
        fetchPaymentMethods();
    }

    useEffect(() => {
        return () => {
            dispatch(onResetStripeStates());
            // Reset states here
            setCurrentPaymentMethod(null);
            setDeleteModal(false);
            setAddModal(false);
            setHasFetchedPaymentMethods(false);
        };
    }, []);

    document.title = "Payment Methods | Omnitouch CRM";
    return (
        <React.Fragment>
            <div className={customer === null && 'page-content'}>
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <TableCommon
                                title="Payment Methods"
                                data={formattedPaymentMethods}
                                add={customer && toggleAddModal}
                                pagination={pagination}
                                fetchRecords={fetchPaymentMethods}
                            />
                        </Col>
                    </Row>
                    <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handlePaymentMethodConfirmDelete}
                        onCloseClick={() => setDeleteModal(false)}
                    />
                    <ErrorModal
                        show={errorModal}
                        onCloseClick={() => setErrorModal(false)}
                        message={errorModalMessage}
                    />
                    <Elements stripe={stripePromise}>
                        <AddPaymentMethodModal isOpen={addModal} toggle={onToggleAddModal} customerId={customer?.customer_id} onClose={handleOnAddPaymentMethodClose}/>
                    </Elements>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default PaymentMethods;