// Dashboards.js

import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';

// Parse Grafana URLs and Labels from environment variables
const DASHBOARD_URLS = process.env.REACT_APP_GRAFANA_URLS
  ? process.env.REACT_APP_GRAFANA_URLS.split(',')
  : [];
const DASHBOARD_LABELS = process.env.REACT_APP_GRAFANA_LABELS
  ? process.env.REACT_APP_GRAFANA_LABELS.split(',')
  : [];

const Dashboards = () => {
  const { id } = useParams();
  const dashboardIndex = parseInt(id, 10) - 1; // Convert to zero-based index
  const dashboardUrl = DASHBOARD_URLS[dashboardIndex];
  const dashboardLabel = DASHBOARD_LABELS[dashboardIndex] || `Dashboard ${id}`;

  // Redirect if the URL ID is invalid or out of bounds
  if (!dashboardUrl) {
    return <Navigate to="/dashboards/1" />;
  }

  return (
    <Dashboard url={dashboardUrl} dashboardLabel={dashboardLabel} />
  );
};

export default Dashboards;
