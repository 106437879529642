import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Navigation from "./Navigation";
import Cart from "./Cart";
import DataSelection from "./DataSelection";
import BillingInfo from "./BillingInfo";
import Payment from "./Payment";
import { getUsage, createPaymentIntent, topupDongle, refundStripePayment } from '../../../slices/thunks';
import { loadStripe } from "@stripe/stripe-js";

import defaultLogoSm from "../../../assets/images/omnitouch/DefaultLogoSm.png";
import defaultLogoDark from "../../../assets/images/omnitouch/DefaultLogoDark.png";
import defaultLogoLight from "../../../assets/images/omnitouch/DefaultLogoLight.png";

import Loader from "../../../Components/Common/Loader";

// Function to conditionally import user-uploaded images
const tryImport = (filename) => {
  try {
    return require(`../../../assets/images/omnitouch/${filename}`);
  } catch (err) {
    return null;
  }
};

// Attempt to import user-uploaded images
const userLogoSm = tryImport("logoSm.png");
const userLogoDark = tryImport("logoDark.png");
const userLogoLight = tryImport("logoLight.png");

import "../style.scss";
import { set } from "lodash";

const getLogo = (userLogo, defaultLogo) => userLogo || defaultLogo;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const TopUp = () => {
  const dispatch = useDispatch();
  const [activeVerticalTab, setActiveVerticalTab] = useState(1);
  const [passedVerticalSteps, setPassedVerticalSteps] = useState([1]);
  const [days, setDays] = useState(1);
  const [price, setPrice] = useState(10);
  const [usage, setUsage] = useState({});
  const [clientSecret, setClientSecret] = useState('');
  const [handleStripePayment, setHandleStripePayment] = useState(null);
  const [canProceed, setCanProceed] = useState(false);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [billingInfo, setBillingInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [showStatus, setShowStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [topupFailed, setTopupFailed] = useState(false);

  const handlePayment = () => {
    if (handleStripePayment) {
      handleStripePayment(clientSecret.client_secret);
    }
  };

  useEffect(() => {
    dispatch(getUsage())
      .then((response) => {
        if (response) {
          setUsage(response?.payload || {});
        }
      });
  }, [dispatch]);

  const handleCreatePaymentIntent = async () => {
    try {
      const paymentIntent = await dispatch(createPaymentIntent({
        amount: price * days * 100,
        currency: process.env.REACT_APP_CURRENCY_CODE.toLowerCase(),
        description: 'Dongle top-up',
      })).unwrap();

      setClientSecret(paymentIntent);
    } catch (error) {
      console.error('Error creating payment intent:', error);
    }
  };

  const refundPayment = async (paymentIntentId) => {
    try {
      const refundData = {
        payment_intent_id: paymentIntentId
      }
      dispatch(refundStripePayment(refundData))
        .then((response) => {
          if (response.payload?.status == 'succeeded') {
            setStatusMessage('Refund Successful');
          } else {
            setStatusMessage('Refund Failed');
          }
          setTimeout(() => setActiveVerticalTab(4), 2000);
        });
    } catch (error) {
      console.error('Error refunding payment:', error);
      setStatusMessage('Refund Failed');
      setTimeout(() => setActiveVerticalTab(4), 2000);
    }
  };

  const handlePaymentSuccess = async (paymentIntent) => {
    setShowStatus(true);
    setStatusMessage('Processing Payment...');
    try {
      const topUpData = {
        service_uuid: usage?.service?.service_uuid,
        imsi: usage?.imsi,
        requesting_ip: usage?.requestingIp,
        days: days,
      }
      dispatch(topupDongle(topUpData))
        .then((response) => {
          if (response.payload?.status == 200) {
            if (response.payload?.data?.result.status != 200) {
              setStatusMessage('Topup Failed. Refunding payment...');
              refundPayment(paymentIntent.id);
              setTopupFailed(true);
            } else {
              setStatusMessage('Topup Successful');
              setTimeout(() => setActiveVerticalTab(4), 2000);
            }
          } else {
            setStatusMessage('Topup Failed. Refunding payment...');
            // refund stripe payment
            refundPayment(paymentIntent.id);
            setTopupFailed(true);
          }
        });
    } catch (error) {
      console.error('Error topping up dongle:', error);
      setStatusMessage('Topup Failed. Refunding payment...');
      refundPayment(paymentIntent.id);
      setTopupFailed(true);
    }
  };

  const handleNextStep = (nextTab) => {
    if (nextTab === 2) {
      handleCreatePaymentIntent();
    }
    if (nextTab === 3 && !clientSecret) {
      return; // Ensure clientSecret is available before going to Payment step
    }
    if (nextTab === 4) {
      handlePayment();
    } else {
      toggleVerticalTab(nextTab);
    }
  };

  const toggleVerticalTab = (tab) => {
    if (activeVerticalTab !== tab) {
      const modifiedSteps = [...passedVerticalSteps, tab];
      if (tab >= 1 && tab <= 5) {
        setActiveVerticalTab(tab);
        setPassedVerticalSteps(modifiedSteps);
      }
    }
  };

  return (
    <React.Fragment>
      <Container fluid>
          <Row className="">
            <Col xl={12}>
              <Card className="" style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}>
                <CardHeader className="card-header-custom mb-3" style={{ backgroundColor: 'transparent', border: 'none' }}>
                  <div className="header-content">
                    <Link to="#" className="logo logo-dark">
                      <span className="logo-sm">
                        <img src={getLogo(defaultLogoSm, defaultLogoDark)} className="header-logo" alt="" width={'200'} />
                      </span>
                      <span className="logo-lg">
                        <img src={getLogo(defaultLogoDark, defaultLogoDark)} className="header-logo" alt="" width={'200'} />
                      </span>
                    </Link>
                    <Link to="#" className="logo logo-light">
                      <span className="logo-sm">
                        <img src={getLogo(defaultLogoSm, defaultLogoLight)} className="header-logo" alt="" width={'200'} />
                      </span>
                      <span className="logo-lg">
                        <img src={getLogo(defaultLogoLight, defaultLogoLight)} className="header-logo" alt="" width={'200'} />
                      </span>
                    </Link>
                  </div>
                </CardHeader>
                <CardBody className="mb-0 pb-0" style={{ backgroundColor: 'transparent', border: 'none' }}>
                  <div className="vertical-navs-step form-steps">
                    <Row className="gy-5 gap-0">
                      <Col lg={3} className="equalize-height-col">
                        <Card className="rounded-5 equalize-height-col-item">
                          <CardBody>
                            <Nav className="flex-column custom-nav nav-pills">
                              <NavItem>
                                <NavLink
                                  href="#"
                                  className={classnames({ active: activeVerticalTab === 1, done: activeVerticalTab <= 5 && activeVerticalTab > 1 })}
                                  onClick={() => handleNextStep(1)}
                                >
                                  <span className="step-title me-2">
                                    <i className="ri-close-circle-fill step-icon me-2"></i>
                                    Step 1
                                  </span>
                                  Data Selection
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  href="#"
                                  className={classnames({ active: activeVerticalTab === 2, done: activeVerticalTab <= 5 && activeVerticalTab > 2 })}
                                  onClick={() => handleNextStep(2)}
                                >
                                  <span className="step-title me-2">
                                    <i className="ri-close-circle-fill step-icon me-2"></i>
                                    Step 2
                                  </span>
                                  Billing Info
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  href="#"
                                  className={classnames({ active: activeVerticalTab === 3, done: activeVerticalTab <= 5 && activeVerticalTab >= 3 })}
                                  onClick={() => handleNextStep(3)}
                                >
                                  <span className="step-title me-2">
                                    <i className="ri-close-circle-fill step-icon me-2"></i>
                                    Step 3
                                  </span>
                                  Payment
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  href="#"
                                  className={classnames({ active: activeVerticalTab === 4, done: activeVerticalTab <= 5 && activeVerticalTab >= 4 })}
                                  onClick={() => handleNextStep(4)}
                                >
                                  <span className="step-title me-2">
                                    <i className="ri-close-circle-fill step-icon me-2"></i>
                                    Step 4
                                  </span>
                                  Finish
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={6} className="equalize-height-col">
                        <div>
                          <TabContent activeTab={activeVerticalTab}>
                            <TabPane tabId={1}>
                              <DataSelection days={days} setDays={setDays} price={price} total={price * days} />
                            </TabPane>
                            <TabPane tabId={2}>
                              <BillingInfo
                                activeVerticalTab={activeVerticalTab}
                                toggleVerticalTab={setActiveVerticalTab}
                                saveInfo={setBillingInfo}
                                setCanProceed={setCanProceed}
                              />
                            </TabPane>
                            <TabPane tabId={3}>
                              {showStatus ? (
                                <Card className="rounded-5 equalize-height-col-item">
                                  <CardBody className="text-center">
                                    <div className="pt-4 pb-2">
                                      <Loader style={{ marginBottom: "1rem" }} />
                                      <p>{statusMessage}</p>
                                    </div>
                                  </CardBody>
                                </Card>
                              ) : (
                                <Payment
                                  billingInfo={billingInfo}
                                  resetOnPayment={() => setActiveVerticalTab(1)}
                                  onPaymentSuccess={handlePaymentSuccess}
                                  setHandleStripePayment={setHandleStripePayment}
                                  days={days}
                                  stripePromise={stripePromise}
                                />
                              )}
                            </TabPane>
                            <TabPane tabId={4}>
                              <Card className="rounded-5 equalize-height-col-item">
                                <CardBody>
                                  {topupFailed ? (
                                    <div className="text-center pt-4 pb-2">
                                      <div className="mb-4">
                                        <i className="bx bx-error display-4 text-danger"></i>
                                      </div>
                                      <h5>Your Top-Up Failed!</h5>
                                      <p className="text-muted">
                                        Unfortunately, the top-up could not be completed. Please try again or contact support.
                                      </p>
                                      <p>
                                        If you need help, please <Link to="https://norfone.au" className="text-primary">contact us</Link>.
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="text-center pt-4 pb-2">
                                      <div className="mb-4">
                                        <i className="bx bx-party display-4 text-success"></i>
                                      </div>
                                      <h5>Your Order is Completed!</h5>
                                      <p className="text-muted">
                                        You're online now - enjoy your data!
                                      </p>
                                      <p>
                                        If you have any issues with your order, please don't hesitate to <Link to="https://norfone.au" className="text-primary">contact us</Link>.
                                      </p>
                                    </div>
                                  )}
                                </CardBody>
                              </Card>
                            </TabPane>
                          </TabContent>
                        </div>
                      </Col>
                      <Col lg={3} className="equalize-height-col">
                        <Cart days={days} price={price} total={price * days} />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
                <CardFooter className="mt-1 pt-2" style={{ backgroundColor: 'transparent', border: 'none' }}>
                  <Navigation
                    activeVerticalTab={activeVerticalTab}
                    toggleVerticalTab={handleNextStep}
                    handlePayment={handlePayment}
                    canProceed={canProceed}
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
      </Container>
    </React.Fragment>
  );
};

export default TopUp;