import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getInventoryByCustomerId,
    deleteInventory,
    updateInventory,
    getAllInventoryTemplates,
} from '../../../../slices/thunks';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TableCommon from '../../../../Components/Common/TableCommon';
import DeleteModal from '../../../../Components/Common/DeleteModal';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import InventoryCreate from '../../../Inventory/InventoryCreate';
import * as Icons from 'react-icons/fa';

const CustomerInventoryList = ({ customerId, type = null }) => {
    const dispatch = useDispatch();

    const [inventoryItems, setInventoryItems] = React.useState([]);
    const [formattedInventoryItems, setFormattedInventoryItems] = React.useState([]);
    const [pagination, setPagination] = React.useState({
        currentPage: 1,
        itemsPerPage: 10,
        totalRecords: 0,
        totalPages: 0,
    });
    const [sortConfig, setSortConfig] = React.useState({ key: 'inventory_id', direction: 'desc' });
    const sortableColumns = [ 'inventory_id', 'item', 'item_location', 'retail_cost', 'Serial 1', 'Serial 2', 'wholesale_cost' ];

    const [deleteModal, setDeleteModal] = React.useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = React.useState(false);
    const [selectedIds, setSelectedIds] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [addModal, setAddModal] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [editModal, setEditModal] = React.useState(false);
    const [templates, setTemplates] = React.useState([]);
    const [filterOptions, setFilterOptions] = React.useState({});
    const [filters, setFilters] = React.useState({});

    const toggleAddModal = () => setAddModal(!addModal);
    const toggleEditModal = () => setEditModal(!editModal);

    const handleEditInventory = (inventory) => {
        setSelectedItem(inventory);
        toggleEditModal();
    };

    const handleEditInventoryRow = (inventoryId) => {
        setSelectedItem(inventoryItems.find(inventory => inventory.inventory_id === inventoryId));
        toggleEditModal();
    };

    const handleSaveInventory = () => {
        dispatch(updateInventory(selectedItem))
            .then((response) => {
                toast.success('Inventory item updated successfully');
                fetchInventory();
            })
            .catch((error) => {
                console.log('Error', error);
            });
        toggleEditModal();
    };

    const handleReturnInventory = () => {
        // verify if service_id is null
        if (selectedItem.service_id) {
            toast.error('Inventory item is associated with a service. Please remove the service before returning the item');
            return;
        }

        dispatch(updateInventory({ ...selectedItem, customer_id: null }))
            .then((response) => {
                toast.success('Inventory item returned successfully');
                fetchInventory();
            })
            .catch((error) => {
                console.log('Error', error);
            });
        toggleEditModal();
    };

    const handleDeleteInventory = () => {
        dispatch(deleteInventory(selectedItem.inventory_id))
            .then((response) => {
                toast.success('Inventory item deleted successfully');
                fetchInventory();
            })
            .catch((error) => {
                console.log('Error', error);
            });
        setDeleteModal(false);
    };

    const handleDeleteMultiple = () => {
        if (selectedIds.length === 0) {
            toast.error('Please select at least one record to delete');
            return;
        }
        Promise.all(selectedIds.map((id) => dispatch(deleteInventory(id))))
            .then(() => {
                toast.success('Inventory item deleted successfully');
                fetchInventory();
            })
            .catch((error) => {
                console.log('Error', error);
            });
    };

    const handleDeleteMultipleInventory = (ids) => {
        setSelectedIds(ids);
        setDeleteModalMulti(true);
    };

    const iconClassToComponentName = (iconClass) => {
        if (!iconClass) return null;
        const parts = iconClass.split(' ');
        if (parts.length < 1) return null;
        const iconName = parts[parts.length - 1].slice(3); // remove 'fa-' prefix
        const finalIconName = iconName
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join('');
        return `Fa${finalIconName}`;
    
    };

    const handleFilterChange = (field, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [field]: [value],
        }));
    };

    const applyFilters = () => {
        fetchInventory();
    };

    const resetFilters = () => {
        setFilters({});
    };

    // for use in reset filters to refetch inventory
    useEffect(() => {
        if (Object.keys(filters).length === 0) {
            fetchInventory();
        }
    }, [filters]);

    const fetchInventory = (currentPage = pagination.currentPage, itemsPerPage = pagination.itemsPerPage, key = sortConfig.key, direction = sortConfig.direction, search = searchTerm) => {
        // map key to actual field names
        if (key === 'Serial 1') {
            key = 'serial1';
        } else if (key === 'Serial 2') {
            key = 'serial2';
        } else if (key === 'price') {
            key = 'retail_cost';
        }
        
        dispatch(getInventoryByCustomerId({customerId, currentPage, itemsPerPage, key, direction, searchTerm: search, filters}))
            .then((response) => {
                setInventoryItems(response.payload.data);
                if (response.payload.pagination) {
                    setPagination({
                        currentPage: response.payload.pagination.page,
                        itemsPerPage: response.payload.pagination.per_page,
                        totalRecords: response.payload.pagination.total_records,
                        totalPages: response.payload.pagination.total_pages,
                    });
                }
                setSortConfig({ key, direction });
                setSearchTerm(search);
            })
            .catch((error) => {
                console.log('Error', error);
            });
    };

    const fetchTemplates = () => {
        dispatch(getAllInventoryTemplates())
            .then((response) => {
                setTemplates(response.payload);

                // set filter options
                const options = response.payload.map(template => ({ value: template.inventory_template_id, label: template.item }));
                setFilterOptions(prevOptions => ({ ...prevOptions, inventory_template_id: { options } }));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        fetchInventory();
        fetchTemplates();
    }, [customerId, dispatch]);

    document.title = "Customer Inventory | Omnitouch CRM";
    useEffect(() => {
        let formattedInventoryItems = inventoryItems.map((item) => {
            const template = templates.find(template => template.inventory_template_id === item.inventory_template_id);
            const iconComponentName = iconClassToComponentName(template?.icon);
            const IconComponent = Icons[iconComponentName];
            const icon = IconComponent ? <IconComponent /> : null;
            let formattedItems = {
                icon: icon,
                'id': item.inventory_id,
                'inventory_id': item.inventory_id,
                'item': item.item,
                'item_location': item.item_location,
                'retail_cost': null,
                'price': null,
                'Serial 1': item.serial1,
                'Serial 2': item.serial2,
                'wholesale_cost': null,
                actions: (
                    <>
                        { type !== 'self-care' && (
                            <ul className="list-inline hstack gap-2 mb-0">
                                <li className="list-inline-item edit">
                                    <Link
                                        title='Edit Inventory'
                                        className="text-primary d-inline-block edit-item-btn"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleEditInventory(item);
                                        }}
                                    >
                                        <i className="ri-pencil-fill fs-16"></i>
                                    </Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link
                                        title='Delete Inventory'
                                        className="text-danger d-inline-block remove-item-btn"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedItem(item);
                                            setDeleteModal(true);
                                        }}
                                    >
                                        <i className="ri-delete-bin-5-fill fs-16"></i>
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </>
                ),
            };

            if (type === 'self-care') {
                formattedItems = {
                    ...formattedItems,
                    'price': item.retail_cost,
                };
                // remove retail and wholesale cost columns
                delete formattedItems.retail_cost;
                delete formattedItems.wholesale_cost;
            } else {
                formattedItems = {
                    ...formattedItems,
                    'retail_cost': item.retail_cost,
                    'wholesale_cost': item.wholesale_cost,
                };
                // remove price column
                delete formattedItems.price;
            }

            return formattedItems;
        });

        setFormattedInventoryItems(formattedInventoryItems);
    }, [inventoryItems]);

    return (
        <React.Fragment>
            <Modal isOpen={addModal} toggle={toggleAddModal} centered>
                <ModalHeader toggle={toggleAddModal}>Add Inventory Item</ModalHeader>
                <ModalBody>
                    <InventoryCreate page={false} customerId={customerId}/>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary" onClick={() => {
                        toggleAddModal();
                        fetchInventory();
                    }}>
                        Close
                    </button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={editModal} toggle={toggleEditModal} centered>
                <ModalHeader toggle={toggleEditModal}>Edit Inventory Item</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="form-group">
                            <label>Item</label>
                            <input type="text" className="form-control" value={selectedItem?.item} onChange={(e) => setSelectedItem({...selectedItem, item: e.target.value})} />
                        </div>
                        <div className="form-group">
                            <label>Item Location</label>
                            <input type="text" className="form-control" value={selectedItem?.item_location} onChange={(e) => setSelectedItem({...selectedItem, item_location: e.target.value})} />
                        </div>
                        <div className="form-group">
                            <label>Serial 1</label>
                            <input type="text" className="form-control" value={selectedItem?.serial1} onChange={(e) => setSelectedItem({...selectedItem, serial1: e.target.value})} />
                        </div>
                        <div className="form-group">
                            <label>Serial 2</label>
                            <input type="text" className="form-control" value={selectedItem?.serial2} onChange={(e) => setSelectedItem({...selectedItem, serial2: e.target.value})} />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-secondary" onClick={toggleEditModal}>
                        Close
                    </button>
                    { selectedItem?.service_id == null && selectedItem?.customer_id &&(
                        <button type='button' className='btn btn-primary' onClick={handleReturnInventory}>
                            Return to Inventory
                        </button>
                    )}
                    <button type="button" className="btn btn-success" onClick={handleSaveInventory}>
                        Save
                    </button>
                </ModalFooter>
            </Modal>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteInventory}
                onCloseClick={() => setDeleteModal(false)}
            />
            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                    handleDeleteMultiple();
                    setDeleteModalMulti(false);
                }}
                onCloseClick={() => setDeleteModalMulti(false)}
            />
            <TableCommon
                title={'Customer Inventory'}
                data={formattedInventoryItems}
                pagination={pagination}
                sortConfig={sortConfig}
                sortableColumns={sortableColumns}
                fetchRecords={fetchInventory}
                add={ type === 'self-care' ? null : toggleAddModal}
                multiDelete={ type === 'self-care' ? null : handleDeleteMultipleInventory}
                onRowClick={ type === 'self-care' ? null : handleEditInventoryRow}
                filters={{
                    filterOptions: filterOptions,
                    handleFilterChange: handleFilterChange,
                    applyFilters: applyFilters,
                    resetFilters: resetFilters
                }}
            />
        </React.Fragment>
    );
};

export default CustomerInventoryList;