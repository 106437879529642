import React, { useEffect } from 'react';
import { FormGroup } from "reactstrap";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
    hidePostalCode: true,
};

const StripeCheckout = ({ billingInfo = {}, onPaymentSuccess = null, setHandleStripePayment }) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (clientSecretData) => {
        if (!stripe || !elements || !clientSecretData) {
            console.error('Stripe.js has not loaded yet or client secret is missing.');
            return;
        }

        const cardElement = elements.getElement(CardElement);

        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: `${billingInfo?.firstName} ${billingInfo?.lastName}`,
                    email: billingInfo?.email,
                },
            });

            if (error) {
                console.error('Error creating payment method:', error.message);
                return;
            }

            const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecretData, {
                payment_method: paymentMethod.id,
            });

            if (confirmError) {
                console.error('Error confirming card payment:', confirmError.message);
                return;
            }

            if (paymentIntent?.status === 'succeeded' && onPaymentSuccess) {
                onPaymentSuccess(paymentIntent);
            }
        } catch (err) {
            console.error('Unexpected error during payment:', err);
        }
    };

    useEffect(() => {
        if (stripe && elements) {
            setHandleStripePayment(() => handleSubmit);
        }
    }, [stripe, elements, setHandleStripePayment]);

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <FormGroup className="card-input-wrapper form-control shadow-sm p-2 mb-4 bg-white rounded mt-3">
                <CardElement options={CARD_ELEMENT_OPTIONS} />
            </FormGroup>
        </form>
    );
};

export default StripeCheckout;
