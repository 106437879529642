import React, { useEffect, useState } from "react";
import { Card, CardFooter, CardBody, CardHeader, Row, Col, Label, Input } from "reactstrap";

const BillingInfo = ({ saveInfo, activeVerticalTab, toggleVerticalTab, setCanProceed }) => {
    const [billingInfo, setBillingInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
    });

    useEffect(() => {
        if (saveInfo) {
            saveInfo(billingInfo);
        }

        // Check if all fields are filled
        const allFieldsFilled = Object.values(billingInfo).every((value) => value.trim() !== "");
        setCanProceed(allFieldsFilled);
    }, [billingInfo, saveInfo, setCanProceed]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setBillingInfo((prev) => ({ ...prev, [id]: value }));
    };

    return (
        <React.Fragment>
            <Card className="rounded-5 equalize-height-col-item">
                <CardHeader className="py-3 pb-0 rounded-5">
                    <h5>Billing Info</h5>
                    <p className="text-muted fs-6">
                        We'll send your receipt to the email address you provide below, and let you know when your data is about to expire.
                    </p>
                </CardHeader>

                <CardBody>
                    <Row className="g-3">
                        <Col sm={6}>
                            <Label htmlFor="firstName" className="form-label">
                                First name
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="firstName"
                                placeholder="Enter First Name"
                                value={billingInfo.firstName}
                                onChange={handleChange}
                            />
                        </Col>

                        <Col sm={6}>
                            <Label htmlFor="lastName" className="form-label">
                                Last name
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="lastName"
                                placeholder="Enter Last Name"
                                value={billingInfo.lastName}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12}>
                            <Label htmlFor="email" className="form-label">
                                Email <span className="text-muted">(For Receipt)</span>{" "}
                            </Label>
                            <Input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Enter Email"
                                value={billingInfo.email}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default BillingInfo;
