// src/utils/dateUtils.ts

/**
 * Convert a UTC date string to the local date string
 * @param {string} utcDate - UTC date string (e.g., '2024-08-20T02:11:50Z')
 * @returns {string} - Local date string (e.g., '8/19/2024')
 */
export const convertToLocalDateString = (utcDate: string): string => {
    const dateObj = new Date(utcDate);
    return dateObj.toLocaleDateString(); // Converts to local date format
};

/**
 * Convert a UTC date string to the local time string
 * @param {string} utcDate - UTC date string (e.g., '2024-08-20T02:11:50Z')
 * @returns {string} - Local time string (e.g., '10:11:50 PM')
 */
export const convertToLocalTimeString = (utcDate: string): string => {
    const dateObj = new Date(utcDate);
    return dateObj.toLocaleTimeString(); // Converts to local time format
};

/**
 * Convert a UTC date string to the local date and time string
 * @param {string} utcDate - UTC date string (e.g., '2024-08-20T02:11:50Z')
 * @returns {string} - Local date and time string (e.g., '8/19/2024, 10:11:50 PM')
 */
export const convertToLocalDateTimeString = (utcDate: string): string => {
    const dateObj = new Date(utcDate);
    return `${dateObj.toLocaleDateString()}, ${dateObj.toLocaleTimeString()}`; // Combines local date and time
};
