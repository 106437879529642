import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import AllTransactions from './AllTransactions';
import Widgets from './Widgets';

const Transactions = ({customer = null, showWidgets = true, type = null}) => {
    document.title="Transactions | Velzon - React Admin & Dashboard Template";
    return (
        <React.Fragment>
            <div className={customer === null && 'page-content'}>
                <Container fluid>
                    {/* <BreadCrumb title="Transactions" pageTitle="Crypto" /> */}
                    <h4 className="card-title mb-4">Transactions</h4>
                    {showWidgets && (
                        <Row>
                            <Widgets customer={customer}/>
                        </Row>
                    )}
                    <AllTransactions customer={customer} type={type} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Transactions;