import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';

import {
  createInventoryTemplateItem as onCreateInventoryTemplateItem,
  updateInventoryTemplateById as onUpdateInventoryTemplateById,
} from '../../../slices/thunks';
import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';

function InventoryTemplateForm({ initialData, onSubmit = null, onClose = null }) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    icon: initialData.icon,
    inventory_template_id: initialData.inventory_template_id,
    item: initialData.item,
    retail_cost: initialData.retail_cost,
    serial1_label: initialData.serial1_label,
    serial2_label: initialData.serial2_label,
    wholesale_cost: initialData.wholesale_cost,
    allow_dropdown_staff: initialData.allow_dropdown_staff,
    allow_dropdown_customer: initialData.allow_dropdown_customer,
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (initialData.inventory_template_id) {
      const updatedData = {
        ...initialData,
        ...formData,
      };
      dispatch(onUpdateInventoryTemplateById(updatedData))
        .then(() => {
          toast.success('Inventory Template Updated Successfully');
          if (onSubmit) {
            onSubmit();
          }
        })
        .catch((error) => {
          toast.error('Inventory Template Update Failed');
          console.error(error);
        });
    } else {
      dispatch(onCreateInventoryTemplateItem(formData))
        .then(() => {
          toast.success('Inventory Template Created Successfully');
          if (onSubmit) onSubmit();
        })
        .catch((error) => {
          toast.error('Inventory Template Create Failed');
          console.error(error);
        });
    }

  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="icon">Icon:</Label>
            <Input type="text" name="icon" id="icon" value={formData.icon} onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="item">Item:</Label>
            <Input type="text" name="item" id="item" value={formData.item} onChange={handleChange} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="wholesale_cost">Wholesale Cost:</Label>
            <Input type="number" name="wholesale_cost" id="wholesale_cost" value={formData.wholesale_cost} onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="retail_cost">Retail Cost:</Label>
            <Input type="number" name="retail_cost" id="retail_cost" value={formData.retail_cost} onChange={handleChange} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="serial1_label">Serial1 Label:</Label>
            <Input type="text" name="serial1_label" id="serial1_label" value={formData.serial1_label} onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="serial2_label">Serial2 Label:</Label>
            <Input type="text" name="serial2_label" id="serial2_label" value={formData.serial2_label} onChange={handleChange} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" name="allow_dropdown_staff" id="allow_dropdown_staff" checked={formData.allow_dropdown_staff} onChange={handleCheckboxChange} />
              Allow Dropdown Staff
            </Label>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" name="allow_dropdown_customer" id="allow_dropdown_customer" checked={formData.allow_dropdown_customer} onChange={handleCheckboxChange} />
              Allow Dropdown Customer
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end">
          <Button type="submit" className='btn-success' onClick={handleSubmit}>Save</Button>
        </Col>
      </Row>
    </Form>
  );
}

export default InventoryTemplateForm;