import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  getInvoices as getInvoicesApi,
  addNewInvoice as addNewInvoiceApi,
  updateInvoice as updateInvoiceApi,
  deleteInvoice as deleteInvoiceApi,
  getInvoiceById as getInvoiceByIdApi,
  getInvoicesByCustomerId as getInvoicesByCustomerIdApi,
  getInvoicePdfById as getInvoicePdfByIdApi,
  getInvoiceStats as getInvoiceStatsApi,
  getInvoiceStatsByCustomerId as getInvoiceStatsByCustomerIdApi,
} from "../../helpers/backend_helper";

export const getInvoices = createAsyncThunk("invoice/getInvoices", async ({page = 1, perPage = 10, sort = 'invoice_id', sortOrder= 'desc', searchTerm = '', filters = {}}) => {
  try {
    const response = await getInvoicesApi(page, perPage, sort, sortOrder, searchTerm, filters);
    return response;
  } catch (error) {
    return error;
  }
});

export const addNewInvoice = createAsyncThunk("invoice/addNewInvoice", async (invoice) => {
  try {
    const response = await addNewInvoiceApi(invoice);
    return response;
  } catch (error) {
    return error;
  }
});

export const getInvoiceById = createAsyncThunk("invoice/getInvoiceById", async (invoice) => {
  try {
    const response = await getInvoiceByIdApi(invoice);
    return response;
  } catch (error) {
    return error;
  }
});

export const getInvoicesByCustomerId = createAsyncThunk("invoice/getInvoicesByCustomerId", async ({customerId, page = 1, perPage = 10, sort = 'invoice_id', sortOrder= 'desc', searchTerm = '', filters}) => {
  try {
    const response = await getInvoicesByCustomerIdApi(customerId, page, perPage, sort, sortOrder, searchTerm, filters);
    return response;
  } catch (error) {
    return error;
  }
});

export const getInvoicePdfById = createAsyncThunk("invoice/getInvoicePdfById", async (invoiceId) => {
  try {
    const response = await getInvoicePdfByIdApi(invoiceId);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateInvoice = createAsyncThunk("invoice/updateInvoice", async (invoice) => {
  try {
    const response = await updateInvoiceApi(invoice);
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteInvoice = createAsyncThunk("invoice/deleteInvoice", async (invoice, {fulfillWithValue, rejectWithValue}) => {
  try {
    const response = await deleteInvoiceApi(invoice);
    return fulfillWithValue(response);
  }
  catch (error) {
    return rejectWithValue(error);
  }
});

export const getInvoiceStats = createAsyncThunk("invoice/getInvoiceStats", async () => {
  try {
    const response = await getInvoiceStatsApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const getInvoiceStatsByCustomerId = createAsyncThunk("invoice/getInvoiceStatsByCustomerId", async (customerId) => {
  try {
    const response = await getInvoiceStatsByCustomerIdApi(customerId);
    return response;
  } catch (error) {
    return error;
  }
});

export const resetInvoiceStates = createAsyncThunk("invoice/resetInvoiceStates", async () => {
  return {};
});