// SelfCare/SelfCare.js

import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Usage from './components/Usage';
import SiteListPage from '../Customer/CustomerOverview/SiteList';
import ServiceList from '../Customer/CustomerOverview/Services';
import ContactList from '../Contacts/ContactList';
import Billing from '../Billing';
import ActivitiesComms from './components/ActivitiesComms';
import CustomerInventoryList from '../Customer/CustomerOverview//Inventory/InventoryList';
import { useDispatch } from 'react-redux';
import Loader from '../../Components/Common/Loader';
import Redirect from '../../Components/Common/Redirect';

import { getCustomerById, resetCustomer, logoutUser } from '../../slices/thunks';

import "./components/SelfCare.scss";

const SelfCare = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [customer, setCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const authUser = JSON.parse(sessionStorage.getItem('authUser'));
    const customerId = authUser?.data?.customer_id ?? null;
    const roles = authUser?.data?.roles ?? null;

    useEffect(() => {
        const fetchCustomer = async () => {
            if (customerId) {
                try {
                    const customerData = await dispatch(getCustomerById(customerId)).unwrap();
                    setCustomer(customerData);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchCustomer();
    }, [customerId, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetCustomer());
        };
    }, [dispatch]);

    useEffect(() => {
        if (!loading && !customer && customerId) {
            setTimeout(() => {
                if (roles && roles.includes('admin')) {
                    navigate('/customers');
                } else {
                    dispatch(logoutUser()).then(() => {
                        navigate('/login');
                    });
                }
            }, 5000);
        }
    }, [loading, customer, customerId, roles, navigate, dispatch]);

    const redirectComponent = () => {

      // redirect in 5 seconds
      setTimeout(() => {
        if (roles && roles.includes('admin')) {
            navigate('/customers');
        } else {
            dispatch(logoutUser()).then(() => {
                navigate('/login');
            });
        }
      }, 5000);

      return (
        <div>
            <h1>Customer not found</h1>
            {roles && roles.includes('admin') ? (
                <p>Redirecting to <a href="/customers">Admin dashboard</a> in 5 seconds</p>
            ) : (
                <p>Redirecting to <a href="/login">login</a> page in 5 seconds</p>
            )}
        </div>
      );
    }

    if (!customerId) {
        return (
            <Redirect
                component={redirectComponent}
            />
        );
    } else if (loading) {
        return (
            <div className="page-content">
                <Loader />
            </div>
        );
    }

    return (
        <Layout>
            <Routes>
                <Route path="usage" element={<Usage customerId={customerId} />} />
                <Route path="sites" element={<SiteListPage customer={customer} />} />
                <Route path="services" element={<ServiceList customer={customer} customerId={customer?.customer_id} customer_name={customer?.customer_name} type={'self-care'} />} />
                <Route path="contacts" element={<ContactList customer={customer} customerId={customer?.customer_id} type={'self-care'} />} />
                <Route path="billing" element={<Billing customer={customer} showWidgets={false} type={'self-care'} />} />
                <Route path="activity" element={<ActivitiesComms customerId={customer?.customer_id} type={'self-care'}/>} />
                <Route path="inventory" element={<CustomerInventoryList customerId={customer?.customer_id} type={'self-care'} />} />
                <Route path="/" element={<Navigate to="/self-care/sites" />} /> {/* Default Route */}
            </Routes>
        </Layout>
    );
};

export default SelfCare;
