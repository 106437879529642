import React, { useState } from 'react';
import {
    Nav,
    NavItem,
    NavLink,
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Button
} from 'reactstrap';
import classnames from 'classnames';
import TopUp from './components/TopUp';
import Usage from './components/Usage';

const TopupAndUsage = () => {
    const [activePage, setActivePage] = useState('topup');
    const [showExplainer, setShowExplainer] = useState(true);

    const handleNavigation = (page) => {
        setActivePage(page);
    };

    const closeModal = () => {
        setShowExplainer(false);
    };

    return (
        <div className={`page-content-topup ${showExplainer ? 'blurred' : ''}`}>
            <Modal isOpen={showExplainer} centered>
                <ModalHeader>Norfone WiFi Dongles</ModalHeader>
                <ModalBody>
                    <p>Welcome to Norfone! </p>
                    <p>Our WiFi dongles cost a flat $10 per day for unlimited data.</p>
                    <p>
                        From here you can select the number of days you'll be on island for or need the
                        data for, purchase the days of data, and get online.
                    </p>
                    <div className="text-center">
                        <Button color="primary" onClick={closeModal}>
                            Get Started
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
            <Container fluid>
                <Row className="justify-content-center navigation">
                    <Col xs="12" md="auto" className="text-center mb-3 mb-md-0">
                        <Nav pills className="justify-content-center">
                            <NavItem className="mx-2">
                                <NavLink
                                    className={classnames({ active: activePage === 'topup' })}
                                    onClick={() => handleNavigation('topup')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Topup
                                </NavLink>
                            </NavItem>
                            <span className="align-self-center d-none d-md-inline">|</span>
                            <NavItem className="mx-2">
                                <NavLink
                                    className={classnames({ active: activePage === 'usage' })}
                                    onClick={() => handleNavigation('usage')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Balance
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {activePage === 'topup' && <TopUp />}
                        {activePage === 'usage' && <Usage />}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default TopupAndUsage;
