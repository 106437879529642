import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import TableCommon from '../../../Components/Common/TableCommon';
import {
    getAllProvisioningJobs,
    resetProvisioningServices
} from "../../../slices/thunks";
import { Container, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import ProvisioningStatus from '../ProvisioningStatus';
import { convertToLocalDateTimeString } from '../../../utils/dateUtils.ts';

const provisioningStatusText = {
    0: 'Success',
    1: 'Running',
    2: 'Failed'
};

const ProvisioningList = ({ title, status, type = null }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        provisioningJobs
    } = useSelector(state => state.Provisioning);

    const [provisioningList, setProvisioningList] = useState([]);
    const [provisioningId, setProvisioningId] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'provision_id', direction: 'desc' });
    const [searchTerm, setSearchTerm] = useState('');
    const [pagination, setPagination] = useState({
        currentPage: 1,
        itemsPerPage: 10,
        totalRecords: 0,
        totalPages: 0,
    });
    const [filters, setFilters] = useState({
        provisioning_status: status && Array.isArray(status) ? status : [status]
    });

    const [provisioningModal, setProvisioningModal] = useState(false);
    const toggleProvisioningModal = () => setProvisioningModal(!provisioningModal);

    const handleProvisioningModalClose = (() => {
        setProvisioningModal(false);
    });

    const handleProvisioningView = (provisionId) => {
        setProvisioningId(provisionId);
        setProvisioningModal(true);
    };

    useEffect(() => {
        fetchProvisionings();

        return () => {
            // cleanup
            dispatch(resetProvisioningServices());
        }
    }, []);

    useEffect(() => {
        if (type === 'deprovisioning') {
            setFilters({
                ...filters,
                deprovisioning_playbook: true
            });
        }
    }, [type]);

    useEffect(() => {
        if (provisioningJobs.data && Array.isArray(provisioningJobs.data) ) {
            setProvisioningList(provisioningJobs.data.map(provision => ({
                id: provision.provision_id,
                provision_id: provision.provision_id,
                playbook_description: provision.playbook_description,
                created: convertToLocalDateTimeString(provision.created),
                last_modified: convertToLocalDateTimeString(provision.last_modified),
                provisioning_status: provisioningStatusText[provision.provisioning_status],
                actions: (
                    <Link
                        title='Go to Customer'
                        to={`/customers/${provision.customer_id}#3`}
                        onClick={e => e.stopPropagation()}
                    >
                        <i className="ri-direction-line fs-16"></i>
                    </Link>
                )
            })));
        }
        if (provisioningJobs.pagination) {
            const paginationData = provisioningJobs.pagination;
            setPagination({
                currentPage: paginationData.page,
                itemsPerPage: paginationData.per_page,
                totalRecords: paginationData.total_records,
                totalPages: paginationData.total_pages,
            });
        }
    }, [provisioningJobs]);

    const fetchProvisionings = (page = pagination.currentPage, perPage = pagination.itemsPerPage, sort = sortConfig.key, sortOrder = sortConfig.direction, search = searchTerm) => {
        let searchQuery = search;
    
        // If the service type is deprovisioning, add the Deprovision search query
        if (type === 'deprovisioning') {
            searchQuery = 'Deprovision';
        }
    
        dispatch(getAllProvisioningJobs({ page, perPage, sort, sortOrder, search: searchQuery, filters }))
            .then(response => {
                if (response.payload) {
                    setSortConfig({
                        key: sort,
                        direction: sortOrder
                    });
                    setSearchTerm(searchQuery);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    

    return (
        <React.Fragment>
            <Container fluid>
                <BreadCrumb title="Provisionings" />
                <TableCommon
                    title={title ?? "Provisionings"}
                    data={provisioningList}
                    pagination={pagination}
                    sortConfig={sortConfig}
                    fetchRecords={fetchProvisionings}
                    onRowClick={handleProvisioningView}
                />
                <Modal isOpen={provisioningModal} toggle={toggleProvisioningModal} size='lg' centered>
                    <ModalHeader toggle={handleProvisioningModalClose}>Provisioning Status</ModalHeader>
                    <ModalBody>
                        <ProvisioningStatus provision_id={provisioningId} />
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-danger" onClick={handleProvisioningModalClose}>Close</button>
                    </ModalFooter>
                </Modal>
            </Container>
        </React.Fragment>
    )
}

export default ProvisioningList;