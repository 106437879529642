import React, { useState } from 'react';
import { Form, Row, ButtonGroup, Input, Label, Col, Container, Button, Card, CardBody, CardHeader } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import {
    getCustomers as onGetCustomers,
    addNewTransaction as onAddNewTransaction,
} from '../../../../slices/thunks';
import { useDispatch } from 'react-redux';

import { TRANSACTION } from '../../../../Components/constants/crm';
import { toast } from 'react-toastify';

export default function AddTransaction({ customer = null }) {
    const dispatch = useDispatch();

    const [newTransaction, setNewTransaction] = useState({
        customer_id: customer?.customer_id,
        title: '',
        description: '',
        retail_cost: '',
        wholesale_cost: '',
        service_id: null,
        site_id: null
    });

    const [newTransactionAdditionalState, setNewTransactionAdditionalState] = useState({
        transactionType: TRANSACTION.TYPES.CREDIT,
        creditType: TRANSACTION.CREDIT_TYPES.CASH_PAYMENT
    });

    const [selectedCustomerId, setSelectedCustomerId] = useState([]);
    const [selectCustomerValidation, setSelectCustomerValidation] = useState(false);
    const [newTransactionValidation, setNewTransactionValidation] = useState({
        customer_id: false,
        retail_cost: false,
        wholesale_cost: false,
    });

    const onChangeValidation = (name, value) => {
        setNewTransactionValidation(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onNewTransactionFormChange = (e) => {
        // save to state the new value based on the input's name
        setNewTransaction({
            ...newTransaction,
            [e.target.name]: e.target.value
        });

        // validate the input
        if (e.target.value === '') {
            onChangeValidation(e.target.name, false);
        } else {
            onChangeValidation(e.target.name, true);
        }
    };

    const onNewTransactionAdditionalStateChange = (key, value) => {
        setNewTransactionAdditionalState(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const loadOptions = (inputValue, callback) => {
        dispatch(onGetCustomers({ searchTerm: inputValue }))
            .then((response) => {
                const options = response.payload.data
                    .map(customer => ({ value: customer.customer_id, label: customer.customer_name }));
                
                callback(options);
            });
    };

    const clearNewTransactionFormStates = () => {
        setNewTransaction({
            customer_id: customer?.customer_id,
            retail_cost: '',
            wholesale_cost: '',
        });
        setNewTransactionAdditionalState({
            transactionType: TRANSACTION.TYPES.CREDIT,
            creditType: TRANSACTION.CREDIT_TYPES.CASH_PAYMENT
        });
        setSelectedCustomerId([]);
        setSelectCustomerValidation(false);
        setNewTransactionValidation({
            customer_id: false,
            retail_cost: false,
            wholesale_cost: false,
        });
    };

    const onSubmitValidation = () => {
        let isValid = true;
        let validation = { ...newTransactionValidation };

        if (newTransaction.customer_id === null) {
            validation.customer_id = false;
            isValid = false;
        } else {
            validation.customer_id = true;
        }

        if (newTransaction.retail_cost === '') {
            validation.retail_cost = false;
            isValid = false;
        } else {
            validation.retail_cost = true;
        }

        if (newTransactionAdditionalState.transactionType === TRANSACTION.TYPES.DEBIT && newTransaction.wholesale_cost === '') {
            validation.wholesale_cost = false;
            isValid = false;
        } else {
            validation.wholesale_cost = true;
        }

        setNewTransactionValidation(validation);
        return isValid;
    };

    const onNewTransactionFormSubmit = (e) => {
        e.preventDefault();

        if (!onSubmitValidation()) {
            return;
        }

        let transaction = { ...newTransaction };
        if (newTransactionAdditionalState.transactionType === TRANSACTION.TYPES.CREDIT) {
            // change wholesale_cost to 0
            transaction.wholesale_cost = 0;
            transaction.title = newTransactionAdditionalState.creditType + ': ' + transaction.title;
            transaction.retail_cost = transaction.retail_cost * -1.0;
        }

        dispatch(onAddNewTransaction(transaction))
            .then(() => {
                toast.success('Transaction added successfully', { autoClose: 3000 });
                clearNewTransactionFormStates();
            })
            .catch(error => {
                toast.error('Transaction addition failed', { autoClose: 3000 });
            });
    };

    return (
        <div className={customer === null && 'page-content'}>
            <Container fluid>
                <Card>
                    <CardHeader>
                        <h1 className="h3 mb-3">Add Payment</h1>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            {customer === null && (
                                <>
                                    <Label className='mb-2'>Search a Customer</Label>
                                    <AsyncSelect
                                        loadOptions={loadOptions}
                                        value={selectedCustomerId}
                                        onChange={(selectedOption) => {
                                            setSelectedCustomerId(selectedOption);
                                            setNewTransaction({ ...newTransaction, customer_id: selectedOption.value });
                                            setSelectCustomerValidation(selectedOption.value !== null);
                                        }}
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 2 })
                                        }}
                                    />
                                    {!selectCustomerValidation && <div className="alert alert-danger">Please select a customer</div>}
                                </>
                            )}
                            <h4 className="card-title mb-4">Transaction Type</h4>
                            {/* Radio Button Selection Individual/Business */}
                            <Row>
                                <ButtonGroup>
                                    <Input type="radio" className="btn-check" name="transactionType" id="transactionType1" autoComplete="off" value={TRANSACTION.TYPES.DEBIT} onChange={() => onNewTransactionAdditionalStateChange('transactionType', TRANSACTION.TYPES.DEBIT)} />
                                    <Label className="btn btn-primary" htmlFor="transactionType1">Debit</Label>

                                    <Input type="radio" className="btn-check" name="transactionType" id="transactionType2" autoComplete="off" value={TRANSACTION.TYPES.CREDIT} defaultChecked onChange={() => onNewTransactionAdditionalStateChange('transactionType', TRANSACTION.TYPES.CREDIT)} />
                                    <Label className="btn btn-primary" htmlFor="transactionType2">Credit</Label>
                                </ButtonGroup>
                            </Row>
                            {newTransactionAdditionalState.transactionType === TRANSACTION.TYPES.CREDIT && (
                                <Row>
                                    <Col>
                                        <label htmlFor="credit_type" className="form-label">Credit Type</label>
                                        <Input type="select" id="credit_type" name="credit_type" value={newTransactionAdditionalState.creditType} onChange={(e) => onNewTransactionAdditionalStateChange('creditType', e.target.value)}>
                                            <option>{TRANSACTION.CREDIT_TYPES.CASH_PAYMENT}</option>
                                            <option>{TRANSACTION.CREDIT_TYPES.REFUND}</option>
                                        </Input>
                                    </Col>
                                </Row>    
                            )}
                            <Row>
                                <Col>
                                    <label htmlFor="title" className="form-label">Transaction Name</label>
                                    <input type="text" name='title' onChange={onNewTransactionFormChange} value={newTransaction.title} className="form-control" id="title" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label htmlFor='description' className='form-label'>Description</label>
                                    <textarea name='description' onChange={onNewTransactionFormChange} value={newTransaction.description} className='form-control' id='description'></textarea>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label htmlFor="retail_cost" className="form-label">{newTransactionAdditionalState.transactionType === 'credit' ? 'Credit' : 'Charge'}</Label>
                                    <Input
                                        name='retail_cost'
                                        valid={newTransactionValidation.retail_cost}
                                        invalid={newTransactionValidation.retail_cost === false}
                                        onChange={onNewTransactionFormChange}
                                        value={newTransaction.retail_cost}
                                        type="text"
                                        className="form-control"
                                        id="retail_cost"
                                    />
                                </Col>
                            </Row>
                            { newTransactionAdditionalState.transactionType === TRANSACTION.TYPES.DEBIT && (
                                <Row>
                                    <Col>
                                        <Label htmlFor="wholesale_cost" className="form-label">Wholesale Cost</Label>
                                        <Input
                                            name='wholesale_cost'
                                            valid={newTransactionValidation.wholesale_cost}
                                            invalid={newTransactionValidation.wholesale_cost === false}
                                            onChange={onNewTransactionFormChange}
                                            value={newTransaction.wholesale_cost}
                                            type="text" className="form-control"
                                            id="wholesale_cost"
                                        />
                                    </Col>
                                </Row>
                            )}
                            <Row className='mt-2'>
                                <Col className='d-flex justify-content-end align-items-end gap-2'>
                                    <Button type="button" className="btn btn-info" onClick={clearNewTransactionFormStates}>Clear</Button>
                                    <Button type="button" className="btn btn-success" onClick={onNewTransactionFormSubmit}>Save changes</Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}