import React, { useState } from 'react';
import { Col, Row, Label, Input, Form, Button } from 'reactstrap';
import Select from 'react-select';
import PhoneInput from '../../../Components/Common/PhoneInput';
import { toast } from 'react-toastify';

const UserContactForm = (props) => {
    const contactTypeOptions = [
        { value: 'all', label: 'Contact for All Types' },
        { value: 'technical', label: 'Technical Contact' },
        { value: 'billing', label: 'Billing Contact' },
        { value: 'installation', label: 'Installation Contact' },
        { value: 'enduser', label: 'End User Contact' }
    ];

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === "email") {
            // Set both email and username to the entered email value
            props.onChange({
                ...props.data,
                email: value,
                username: value
            });

            // Validate the email format
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(value)) {
                setValidation((prevValidation) => ({
                    ...prevValidation,
                    email: true
                }));
            } else {
                setValidation((prevValidation) => ({
                    ...prevValidation,
                    email: false
                }));
            }
        } else {
            // Set other fields as usual
            props.onChange({
                ...props.data,
                [name]: value
            });
        }

        onChangeValidation(name, value);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        props.onChange({
            ...props.data,
            user_image: file
        });
    };

    const handleContactTypesChange = (selectedOptions) => {
        const contactTypes = selectedOptions ? selectedOptions.map(option => option.value).join(',') : '';
        props.onChange({
            ...props.data,
            contact_types: contactTypes
        });
    };

    const [validation, setValidation] = useState({
        email: false,
        first_name: false,
        last_name: false,
        phone_number: false,
        password: false,
        password_confirm: false,
      });

    function handleSubmitValidation() {
        const modifiedV = { ...validation };
        var passwordV = document.getElementById("password").value;
        var passwordConfirmV = document.getElementById("passwordConfirm").value;
        var fnm = document.getElementById("firstName").value;
        var lnm = document.getElementById("lastName").value;
        var emailV = document.getElementById("email").value;
        var phoneV = document.getElementById("phoneNumber").value;

        if (emailV === "") {
            modifiedV['email'] = false;
        } else {
            modifiedV['email'] = true;
        }

        if (passwordV === "") {
            modifiedV['password'] = false;
        } else {
            modifiedV['password'] = true;
        }

        if (passwordConfirmV === "") {
            modifiedV['password_confirm'] = false;
        } else if (passwordV !== passwordConfirmV) {
            modifiedV['password_confirm'] = false;
        } else {
            modifiedV['password_confirm'] = true;
        }

        if (fnm === "") {
            modifiedV['first_name'] = false;
        } else {
            modifiedV['first_name'] = true;
        }

        if (lnm === "") {
            modifiedV['last_name'] = false;
        } else {
            modifiedV['last_name'] = true;
        }

        if (phoneV === "") {
            modifiedV['phone_number'] = false;
        } else {
            modifiedV['phone_number'] = true;
        }
        setValidation(modifiedV);

        // Check if any validation has failed
        const validationFailed = Object.values(modifiedV).some(value => value === false);
        return validationFailed;
    }

    //for change tooltip display propery
    const onChangeValidation = (fieldName, value) => {
        const modifiedV  = { ...validation };

        if (fieldName === "password_confirm") {
            if (value !== "" && value === props.data.password) {
                modifiedV[fieldName] = true;
            } else {
                modifiedV[fieldName] = false;
            }
        } else {
            if (value !== "") {
                modifiedV[fieldName] = true;
            } else {
                modifiedV[fieldName] = false;
            }
        }

        setValidation(modifiedV);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Validate Fields
        const validationFailed = handleSubmitValidation();
        if (validationFailed) {
            toast.error('Validation failed. Please check your input and try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return; // Return early to prevent form submission
        }

        props.onNextStep();
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col lg={12}>
                    <Label for="email">Email</Label>
                    <Input
                        type="email"
                        id="email"
                        name="email"
                        value={props.data.email}
                        onChange={handleInputChange}
                        valid={validation["email"] === true}
                        invalid={
                            validation["email"] !== true &&
                            validation["email"] !== null
                        }
                    />
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                    <Label for="password">Password</Label>
                    <Input
                        type="password"
                        id="password"
                        name="password"
                        value={props.data.password}
                        onChange={handleInputChange}
                        valid={validation["password"] === true}
                        invalid={
                            validation["password"] !== true &&
                            validation["password"] !== null
                        }
                    />
                </Col>
                <Col lg={6}>
                    <Label for="passwordConfirm">Confirm Password</Label>
                    <Input
                        type="password"
                        id="passwordConfirm"
                        name="password_confirm"
                        value={props.data.password_confirm}
                        onChange={handleInputChange}
                        valid={validation["password_confirm"] === true}
                        invalid={
                            validation["password_confirm"] !== true &&
                            validation["password_confirm"] !== null
                        }
                    />
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                    <Label for="firstName">First Name</Label>
                    <Input
                        type="text"
                        id="firstName"
                        name="first_name"
                        value={props.data.first_name}
                        onChange={handleInputChange}
                        valid={validation["first_name"] === true}
                        invalid={
                            validation["first_name"] !== true &&
                            validation["first_name"] !== null
                        }
                    />
                </Col>
                <Col lg={6}>
                    <Label for="lastName">Last Name</Label>
                    <Input
                        type="text"
                        id="lastName"
                        name="last_name"
                        value={props.data.last_name}
                        onChange={handleInputChange}
                        valid={validation["last_name"] === true}
                        invalid={
                            validation["last_name"] !== true &&
                            validation["last_name"] !== null
                        }
                    />
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                    <PhoneInput
                        label="Phone Number"
                        id="phoneNumber"
                        name="phone_number"
                        value={props.data.phone_number}
                        onChange={handleInputChange}
                        valid={validation["phone_number"] === true}
                        invalid={
                            validation["phone_number"] !== true &&
                            validation["phone_number"] !== null
                        }
                    />
                </Col>
                <Col lg={6}>
                    <Label for="jobTitle">Job Title</Label>
                    <Input
                        type="text"
                        id="jobTitle"
                        name="job_title"
                        value={props.data.job_title}
                        onChange={handleInputChange}
                    />
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Label for="userImage">Upload User Image</Label>
                    <Input
                        type="file"
                        id="userImage"
                        name="user_image"
                        onChange={handleFileChange}
                    />
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Label for="contactTypes">Contact Types</Label>
                    <Select
                        closeMenuOnSelect={false}
                        isMulti={true}
                        id="contactTypes"
                        name="contact_types"
                        options={contactTypeOptions}
                        onChange={handleContactTypesChange}
                        styles={{
                            multiValueLabel: (styles) => ({
                                ...styles,
                                color: 'white',
                            }),
                        }}
                        value={contactTypeOptions.filter(option =>
                            props.data.contact_types.includes(option.value)
                        )}
                    />
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Label for="contactNotes">Contact Notes</Label>
                    <Input
                        type="textarea"
                        id="contactNotes"
                        name="contact_notes"
                        value={props.data.contact_notes}
                        onChange={handleInputChange}
                    />
                </Col>
            </Row>

            <Row style={{float: 'right'}} className="d-flex justify-content-start align-items-right gap-1 mt-4">
                <Col xs={12} md="auto">
                    <button
                        type="button"
                        className="btn btn-primary btn-label left ms-auto nexttab nexttab"
                        onClick={(event) => {
                            event.preventDefault();
                            props.onPreviousStep && props.onPreviousStep();
                        }}
                    >
                        <i className="ri-arrow-left-line label-icon align-middle fs-16"></i>
                        Back
                    </button>
                </Col>
                <Col xs={12} md="auto" >
                    <button
                        type="submit"
                        className="btn btn-success btn-label right ms-auto nexttab nexttab"
                    >
                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                        Next
                    </button>
                </Col>
            </Row>
        </Form>
    );
};

export default UserContactForm;
