import { createSlice } from "@reduxjs/toolkit";
import { 
    getTransactions,
    addNewTransaction,
    deleteTransaction,
    getTransactionsByCustomerId,
    getTransactionsByInvoiceId,
    processJsonRPCEvent,
    resetTransactionStates,
    getTransactionStats,
    getTransactionStatsByCustomerId,
} from './thunk';

export const initialState = {
    transactions: [],
    transactionsByInvoice: [],
    transaction: {},
    rpcEvents: [],
    error: null,
    transactionStats: {},
    transactionStatsError: {},
};

const TransactionSlice = createSlice({
    name: 'TransactionSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTransactions.fulfilled, (state, action) => {
            state.transactions = action.payload;
        });
        builder.addCase(getTransactions.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(addNewTransaction.fulfilled, (state, action) => {
            state.transaction = action.payload;
        });
        builder.addCase(addNewTransaction.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(deleteTransaction.fulfilled, (state, action) => {
            state.deletedTransaction = action.payload;
            state.deleteTransanctionSuccess = true;
        });
        builder.addCase(deleteTransaction.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(getTransactionsByCustomerId.fulfilled, (state, action) => {
            state.transactions = action.payload;
        });
        builder.addCase(getTransactionsByCustomerId.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(getTransactionsByInvoiceId.fulfilled, (state, action) => {
            state.transactionsByInvoice = action.payload;
        });
        builder.addCase(getTransactionsByInvoiceId.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(processJsonRPCEvent.fulfilled, (state, action) => {
            state.rpcEvents = action.payload;
        });
        builder.addCase(processJsonRPCEvent.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(resetTransactionStates.fulfilled, (state, action) => {
            state.transactions = [];
            state.transaction = {};
            state.rpcEvents = [];
            state.error = null;
        });
        builder.addCase(resetTransactionStates.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(getTransactionStats.fulfilled, (state, action) => {
            state.transactionStats = action.payload;
        });
        builder.addCase(getTransactionStats.rejected, (state, action) => {
            state.transactionStatsError = action.payload.error || null;
        });

        builder.addCase(getTransactionStatsByCustomerId.fulfilled, (state, action) => {
            state.transactionStats = action.payload;
        });
        builder.addCase(getTransactionStatsByCustomerId.rejected, (state, action) => {
            state.transactionStatsError = action.payload.error || null;
        });
    }
});

export default TransactionSlice.reducer;