import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  searchEverything as searchEverythingApi,
} from "../../helpers/backend_helper";

export const searchEverything = createAsyncThunk("crm/searchEverything", async ({ query, page, per_page }) => {
  try {
    const response = await searchEverythingApi(query, page, per_page);
    return response;
  } catch (error) {
    return error;
  }
});
