// Dashboard.js

import React from 'react';
import { Container } from 'reactstrap';

const Dashboard = ({ url, dashboardLabel }) => {
  return (
    <div className="page-content dashboard-container">
        <Container fluid>
            <h3>{dashboardLabel}</h3>
            <iframe
                src={url}
                width="100%"
                height="800"
                frameBorder="0"
                title="Dashboard"
            ></iframe>
        </Container>
    </div>
  );
};

export default Dashboard;
