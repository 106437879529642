// dashboardsConfig.js

// Parse URLs and Labels from environment variables
const DASHBOARD_URLS = process.env.REACT_APP_GRAFANA_URLS
  ? process.env.REACT_APP_GRAFANA_URLS.split(',')
  : [];

const DASHBOARD_LABELS = process.env.REACT_APP_GRAFANA_LABELS
  ? process.env.REACT_APP_GRAFANA_LABELS.split(',')
  : [];

// Combine URLs and Labels into a single array of dashboard objects
export const DASHBOARDS = DASHBOARD_URLS.map((url, index) => ({
  id: `dashboard${index + 1}`,
  label: DASHBOARD_LABELS[index] || `Dashboard ${index + 1}`,
  url,
}));

export const GRAFANA_API_KEY = process.env.REACT_APP_GRAFANA_API_KEY || 'your-api-key';

export { DASHBOARD_URLS, DASHBOARD_LABELS };