import {
  login,
  logout,
  postSocialLogin
} from "../../../helpers/backend_helper";
import { profileSuccess } from "../profile/reducer";
import { setAuthorization } from "../../../helpers/api_helper";

import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from './reducer';

export const loginUser = (user) => async (dispatch) => {
  try {
    const response = await login({
      email: user.email,
      password: user.password
    });

    if (response) {
      await sessionStorage.setItem("authUser", JSON.stringify(response));
      dispatch(loginSuccess(response));
      dispatch(profileSuccess(response));

      setAuthorization(response.token);

      return response; // Return the response to the component
    }
  } catch (error) {
    dispatch(apiError(error));
    return null; // Return null in case of error
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    // Use the actual logout endpoint
    // await logout();
    sessionStorage.removeItem("authUser");
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const socialLogin = (type, history) => async (dispatch) => {
  try {
    // Assuming postSocialLogin is the correct method for social login
    const socialdata = await postSocialLogin(type);

    if (socialdata) {
      // Save token to sessionStorage
      await sessionStorage.setItem("authUser", JSON.stringify(socialdata));
      
      // Dispatch login success actions
      dispatch(loginSuccess(socialdata));
      
      // Redirect after token is saved
      history('/customers');
    }

  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
