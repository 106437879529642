import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Input, Button, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Offcanvas, OffcanvasBody, Container } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import classnames from 'classnames';
import { searchEverything } from '../../slices/utilities/thunk';
import { useDispatch } from 'react-redux';
import Pagination from '../../Components/Common/Pagination';

const searchLinks = {
    customer: 'customers/{customer_id}',
    contact: 'customers/{customer_id}#4',
    site: 'customers/{customer_id}#2',
    inventory: 'customers/{customer_id}#8',
    service: 'customers/{customer_id}#3',
};

const GlobalSearch = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams(location.search);
    const searchQueryFromURL = searchParams.get('query') || '';

    const [searchTerm, setSearchTerm] = useState(searchQueryFromURL);
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (searchTerm) {
            fetchResults(searchTerm, page);
        }
    }, [searchTerm, page]);

    const fetchResults = async (query, page) => {
        setLoading(true);
        try {
            dispatch(searchEverything({ query, page, per_page: perPage }))
                .then((response) => {
                    setResults(response.payload.data);
                    setPage(response.payload.pagination.current_page);
                    setTotalPages(response.payload.pagination.total_pages);
                    setTotalItems(response.payload.pagination.total_items);
                });
        } catch (error) {
            console.error("Error fetching search results:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchTerm.trim()) {
            navigate(`/global-search?query=${searchTerm}`);
            fetchResults(searchTerm, 1);
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const generateSearchLink = (result) => {
        const { type, customer_id } = result;
        const linkTemplate = searchLinks[type];
        if (type === 'inventory' && !customer_id) {
            return 'inventory-items-list';
        } else {
            return linkTemplate ? linkTemplate.replace('{customer_id}', customer_id) : '#';
        }
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleOffCanvas = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="justify-content-center mb-4">
                                    <Col lg={6}>
                                        <Row className="g-2">
                                            <Col>
                                                <div className="position-relative mb-3">
                                                    <Input
                                                        type="text"
                                                        className="form-control form-control-lg bg-light border-light"
                                                        placeholder="Search here..."
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                    />
                                                    {/* <Link
                                                        to="#"
                                                        className="btn btn-link link-success btn-lg position-absolute end-0 top-0"
                                                        onClick={toggleOffCanvas}
                                                    >
                                                        <i className="ri-mic-fill"></i>
                                                    </Link> */}
                                                </div>
                                            </Col>
                                            <div className="col-auto">
                                                <Button
                                                    type="submit"
                                                    className="btn btn-primary btn-lg waves-effect waves-light"
                                                    onClick={handleSearch}
                                                >
                                                    <i className="mdi mdi-magnify me-1"></i> Search
                                                </Button>
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col lg={12}>
                                        <h5 className="fs-16 fw-semibold text-center mb-0">
                                            Showing results for "<span className="text-primary fw-medium fst-italic">{searchTerm}</span>"
                                        </h5>
                                    </Col>
                                </Row>

                                {/* <Offcanvas isOpen={isOpen} direction="top" toggle={toggleOffCanvas} tabIndex={1}>
                                    <OffcanvasBody>
                                        <button
                                            type="button"
                                            onClick={() => setIsOpen(false)}
                                            className="btn-close text-reset float-end"
                                            data-bs-dismiss="offcanvas"
                                            aria-label="Close"
                                        ></button>
                                        <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                                            <div className="search-voice">
                                                <i className="ri-mic-fill align-middle"></i>
                                                <span className="voice-wave"></span>
                                                <span className="voice-wave"></span>
                                                <span className="voice-wave"></span>
                                            </div>
                                            <h4>Talk to me, what can I do for you?</h4>
                                        </div>
                                    </OffcanvasBody>
                                </Offcanvas> */}
                            </CardHeader>

                            {/* Nav Tabs */}
                            <div>
                                <Nav className="nav-tabs nav-tabs-custom" role="tablist"div>
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => toggleTab('1')}
                                        >
                                            <i className="ri-search-2-line text-muted align-bottom me-1"></i> All Results
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>

                            <CardBody className="p-4">
                                {/* Tab Content */}
                                <TabContent activeTab={activeTab} className="text-muted">
                                    <TabPane tabId="1">
                                        {/* Displaying Search Results */}
                                        {loading ? (
                                            <p>Loading...</p>
                                        ) : results && results.length > 0 ? (
                                            <>
                                                <SimpleBar style={{ maxHeight: "60vh" }}>
                                                    {results.map((result, index) => (
                                                        <div className="pb-3" key={index}>
                                                            <h5 className="mb-1">
                                                                <Link to={`/${generateSearchLink(result)}`}>{result.name}</Link>
                                                            </h5>
                                                            <p className="text-success mb-2">Customer ID: {result.customer_id}</p>
                                                            <p className="text-muted mb-2">Type: {result.type}</p>
                                                            <div className="border border-dashed"></div>
                                                        </div>
                                                    ))}
                                                </SimpleBar>

                                                {/* Pagination */}
                                                <Pagination
                                                    dataLength={totalItems}
                                                    currentPage={page}
                                                    setCurrentPage={setPage}
                                                    perPageData={perPage}
                                                    maxPages={7}
                                                />
                                            </>
                                        ) : (
                                            <p>No results found.</p>
                                        )}
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default GlobalSearch;
