import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

// Import components
import CustomerInfo from "./CustomerInfo";
import Site from "./Site";
import UserContactForm from "./UserContactForm";
import StepNavigation from "./StepNavigation";

// Import Redux thunks
import { userSignUp } from "../../../slices/thunks";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    valid: {
      color: '#28a745',
      iconColor: '#28a745',
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hidePostalCode: true,
};

const SignUpForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  // Unified state for user_contact, customer, site, and payment
  const [formData, setFormData] = useState({
    user_contact: {
      username: '',
      email: '',
      password: '',
      password_confirm: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      user_image: null,
      job_title: '',
      contact_notes: '',
      contact_types: "all,"
    },
    customer: {
      customer_name: '',
      customer_payment_type: 'postpaid',
      customer_default_payment_method: '',
      tax_identifier: '',
      lead_source: '',
      promo_code: '',
      customer_notes: ''
    },
    site: {
      site_name: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      latitude: null,
      longitude: null,
      google_maps_place_id: '',
      plus_code: '',
      site_notes: ''
    },
    payment: {
      card_nickname: '',
      stripe_token: '',
      stripe_card: '',
      cc_type: '',
      exp_month: '',
      exp_year: '',
      default_payment_method: true,
      client_ip: ''
    }
  });

  const [formCompletionStatus, setFormCompletionStatus] = useState({
    customerInfo: false,
    contacts: false,
    site: false,
    payment: false,
  });

  const [activeArrowTab, setActiveArrowTab] = useState(4);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);

  // Tab switching function
  const toggleArrowTab = (tab) => {
    if (activeArrowTab !== tab) {
      const modifiedSteps = [...passedarrowSteps, tab];
      if (tab >= 4 && tab <= 9) {
        setActiveArrowTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
  };

  // Update form data for any section
  const handleInputChange = (section, updatedData) => {
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        ...updatedData
      }
    });
  };

  const handleCardNicknameChange = (e) => handleInputChange('payment', { card_nickname: e.target.value });
  const handleDefaultPaymentChange = (e) => handleInputChange('payment', { default_payment_method: e.target.checked });

  // Handle form submission for userSignUp endpoint
  const handleSubmitSignUp = async () => {
    if (!stripe || !elements) {
      toast.error('Stripe not loaded. Please try again.', { autoClose: 3000 });
      return;
    }

    const card = elements.getElement(CardElement);
    const { token, error } = await stripe.createToken(card);

    if (error) {
      console.error('Stripe token creation failed:', error);
      toast.error('Payment information invalid. Please try again.', { autoClose: 3000 });
      return;
    }

    // Add Stripe token data to payment formData
    const {
      id: stripe_token,
      card: { id: stripe_card, brand: cc_type, exp_month, exp_year },
      client_ip
    } = token;

    const updatedFormData = {
      ...formData,
      payment: {
        ...formData.payment,
        stripe_token,
        stripe_card,
        cc_type,
        exp_month,
        exp_year,
        client_ip,
      }
    };

    // Dispatch userSignUp action (Redux thunk)
    dispatch(userSignUp(updatedFormData))
      .then((response) => {
        // check if response is successful with status code 201
        if (response && response.message === 'User registered successfully' && response.status === 201) {
          toast.success('Sign Up successful!', { autoClose: 3000 });
          toggleArrowTab(activeArrowTab + 1);

          // navigate to login page after successful signup after 3 seconds
          setTimeout(() => {
            navigate(`/login`);
          }, 3000);
        } else {
          toast.error('Sign Up failed. Please try again.', { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.error('Sign Up failed:', error);
        toast.error('Sign Up failed. Please try again.', { autoClose: 3000 });
      });
  };

  document.title = "Customer SignUp | Omnitouch CRM";

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">Sign Up</h4>
              </CardHeader>
              <CardBody>
                <StepNavigation
                  activeTab={activeArrowTab}
                  toggleTab={toggleArrowTab}
                  formCompletionStatus={formCompletionStatus}
                />

                <TabContent activeTab={activeArrowTab}>
                  {/* Customer Info Tab */}
                  <TabPane id="steparrow-gen-info" tabId={4}>
                    <CustomerInfo
                      data={formData.customer}
                      onChange={(data) => handleInputChange('customer', data)}
                      onNextStep={() => {
                        setFormCompletionStatus(prevState => ({ ...prevState, customerInfo: true }));
                        toggleArrowTab(activeArrowTab + 1);
                      }}
                    />
                  </TabPane>

                  {/* Contact Info Tab */}
                  {activeArrowTab === 5 && (
                    <TabPane id="steparrow-contacts-info" tabId={5}>
                      <UserContactForm
                        data={formData.user_contact}
                        onChange={(data) => handleInputChange('user_contact', data)}
                        onPreviousStep={() => toggleArrowTab(activeArrowTab - 1)}
                        onNextStep={() => {
                          setFormCompletionStatus(prevState => ({ ...prevState, contacts: true }));
                          toggleArrowTab(activeArrowTab + 1);
                        }}
                      />
                    </TabPane>
                  )}

                  {/* Site Info Tab */}
                  {activeArrowTab === 6 && (
                    <TabPane id="steparrow-site-info" tabId={6}>
                      <Site
                        data={formData.site}
                        onChange={(data) => handleInputChange('site', data)}
                        onPreviousStep={() => toggleArrowTab(activeArrowTab - 1)}
                        onNextStep={() => {
                          setFormCompletionStatus(prevState => ({ ...prevState, site: true }));
                          toggleArrowTab(activeArrowTab + 1);
                        }}
                      />
                    </TabPane>
                  )}

                  {/* Payment Info Tab */}
                  {activeArrowTab === 7 && (
                    <TabPane id="steparrow-payment-info" tabId={7}>
                      <FormGroup>
                        <Label for="cardNickname">Card Nickname</Label>
                        <Input
                          type="text"
                          id="cardNickname"
                          value={formData.payment.card_nickname}
                          onChange={handleCardNicknameChange}
                          placeholder="e.g., My Primary Card"
                          valid={formData.payment.card_nickname.length > 0}
                          invalid={formData.payment.card_nickname.length === 0}
                        />
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            id="defaultPaymentCheckbox"
                            checked={formData.payment.default_payment_method}
                            onChange={handleDefaultPaymentChange}
                          />
                          Set as default payment method
                        </Label>
                      </FormGroup>
                      <FormGroup className="card-input-wrapper form-control shadow-sm p-2 mb-4 bg-white rounded mt-3">
                          <CardElement options={CARD_ELEMENT_OPTIONS} />
                      </FormGroup>
                      <Row style={{float: 'right'}} className="d-flex justify-content-start align-items-right gap-1 mt-4">
                          <Col xs={12} md="auto">
                              <button
                                  type="button"
                                  className="btn btn-primary btn-label left ms-auto nexttab nexttab"
                                  onClick={(event) => {
                                      event.preventDefault();
                                      toggleArrowTab(activeArrowTab - 1);
                                  }}
                              >
                                  <i className="ri-arrow-left-line label-icon align-middle fs-16"></i>
                                  Back
                              </button>
                          </Col>
                          <Col xs={12} md="auto" >
                              <button
                                    type="button"
                                    className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                    onClick={handleSubmitSignUp}
                                >
                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                    Create account
                              </button>
                          </Col>
                      </Row>
                    </TabPane>
                  )}

                  {/* Finish Tab */}
                  {activeArrowTab === 8 && (
                    <TabPane id="steparrow-finish-info" tabId={8}>
                      <div className="text-center">
                        <div className="avatar-md mt-5 mb-4 mx-auto">
                          <div className="avatar-title bg-light text-success display-4 rounded-circle">
                            <i className="ri-checkbox-circle-fill"></i>
                          </div>
                        </div>
                        <h5>Well Done!</h5>
                        <p className="text-muted">
                          You have successfully registered or signed up!
                        </p>
                        <Button color="success" onClick={() => navigate(`/login`)}>
                          Go to Login
                        </Button>
                      </div>
                    </TabPane>
                  )}
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default SignUpForm;
