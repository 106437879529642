import React from 'react';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';

const Cart = ({ days = 0, price = 0, total = 0 }) => {
    const calculateExpiryDate = () => {
        const today = new Date();
        today.setDate(today.getDate() + days);
        return today.toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "2-digit" });
    };

    return (
        <React.Fragment>
            <Card className="rounded-5 equalize-height-col-item">
                <CardHeader className="d-flex justify-content-between align-items-center mb-3 rounded-5 pt-3">
                    <h5 className="fs-14 text-primary mb-0">
                        <i className="ri-shopping-cart-fill align-middle me-2"></i>{" "}
                        Your cart
                    </h5>
                    <span className="badge bg-danger rounded-pill">
                        {days}
                    </span>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <ul className="list-group mb-3">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">Days of Data</h6>
                                    </div>
                                    <span className="text-muted">{days}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">Price Per Day</h6>
                                    </div>
                                    <span className="text-muted">${price}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between">
                                    <span>Total (AUD)</span>
                                    <strong>${total}</strong>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>Unlimited data until</span>
                                    <strong style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                                        {calculateExpiryDate()}
                                    </strong>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default Cart;
