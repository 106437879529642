// src/Components/Common/Pagination.js

import React, { useEffect } from "react";
import { Row } from "reactstrap";

const Pagination = ({ dataLength, currentPage, setCurrentPage, perPageData, maxPages = 7 }) => {

    const handleClick = (e) => {
        setCurrentPage(e);
    };

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(dataLength / perPageData); i++) {
        pageNumbers.push(i);
    }

    const handlePrevPage = () => {
        let prevPage = currentPage - 1;
        setCurrentPage(prevPage);
    };

    const handleNextPage = () => {
        let nextPage = currentPage + 1;
        setCurrentPage(nextPage);
    };

    useEffect(() => {
        if (pageNumbers.length && pageNumbers.length < currentPage) {
            setCurrentPage(pageNumbers.length);
        }
    }, [pageNumbers.length, currentPage, setCurrentPage]);

    const getDisplayedPages = () => {
        const totalPages = pageNumbers.length;
        const pages = [];

        if (totalPages <= maxPages) {
            return pageNumbers;
        }

        const startPage = Math.max(2, currentPage - 1);
        const endPage = Math.min(totalPages - 1, currentPage + 1);

        pages.push(1);

        if (startPage > 2) {
            pages.push('...');
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        if (endPage < totalPages - 1) {
            pages.push('...');
        }

        pages.push(totalPages);

        return pages;
    };

    return (
        <React.Fragment>
            <Row className="g-0 justify-content-end mb-2 mt-2">
                <div className="col-sm">
                    <div className="text-muted">
                        Showing 
                        <span className="fw-semibold ms-1">
                        {dataLength === 0 ? 0 : (perPageData * (currentPage - 1) + 1)} -{' '}
                        {Math.min(perPageData * currentPage, dataLength)}
                        </span> 
                        {' '}of{' '} 
                        <span className="fw-semibold">{dataLength}</span> 
                        {' '}Results
                    </div>
                </div>
                <div className="col-sm-auto">
                    <ul className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                        {currentPage <= 1 ? (
                            <button className="page-item pagination-prev disabled" onClick={e => e.preventDefault()}>
                                Previous
                            </button>
                        ) :
                            <li className={currentPage <= 1 ? "page-item disabled" : "page-item"}>
                                <button className="page-link" onClick={handlePrevPage}>Previous</button>
                            </li>
                        }

                        {getDisplayedPages().map((item, key) => (
                            <React.Fragment key={key}>
                                {item === '...' ? (
                                    <li className="page-item disabled"><span className="page-link">...</span></li>
                                ) : (
                                    <li className="page-item">
                                        <button className={currentPage === item ? "page-link active" : "page-link"} onClick={() => handleClick(item)}>{item}</button>
                                    </li>
                                )}
                            </React.Fragment>
                        ))}

                        {currentPage >= pageNumbers.length ? (
                            <button className="page-item pagination-next disabled ms-1"  onClick={e => e.preventDefault()}>
                                Next
                            </button>
                        ) :
                            <li className={currentPage >= pageNumbers.length ? "page-item disabled" : "page-item"}>
                                <button className="page-link" onClick={handleNextPage}>Next</button>
                            </li>
                        }
                    </ul>
                </div>
            </Row>
        </React.Fragment>
    );
}

export default Pagination;